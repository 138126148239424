/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { Box, CircularProgress, Typography } from '@mui/material';
import { driver, DriveStep } from 'driver.js';
import { useEffect, useState } from 'react';

import { EvaluationType, StepTour } from '../../api/enumerations';
import { AttachFilesDialog } from '../../components/Dialog/AttachFilesDialog';
import ConfirmationDialog from '../../components/Dialog/ConfirmationDialog';
import { MessagesDialog } from '../../components/Dialog/Messages';
import { PeptDialog } from '../../components/Dialog/PeptDialog';
import { ApproveConservation } from '../../components/EditableSections/ApproveConservation';
import { ClientDataEdit } from '../../components/EditableSections/ClientData';
import { PropertyDataEdit } from '../../components/EditableSections/PropertyData';
import { PropertyInfoEdit } from '../../components/EditableSections/PropertyInfo';
import { AccordionTitle } from '../../components/Sections/AccordionTitle';
import { ClientData } from '../../components/Sections/ClientData';
import { EditWorkOrder } from '../../components/Sections/EditWorkOrder';
import { FormResponse } from '../../components/Sections/FormResponse';
import { WorkOrderProgressBar } from '../../components/Sections/ProgressBar';
import { PropertyAddressWithMaps } from '../../components/Sections/PropertyAddressWithMaps';
import { PropertyData } from '../../components/Sections/PropertyData';
import { PropertyInfo } from '../../components/Sections/PropertyInfo';
import { Rooms } from '../../components/Sections/Rooms';
import { ScheduleInfo } from '../../components/Sections/ScheduleInfo';
import { Title } from '../../components/Sections/Title';
import { ToogleAccordion } from '../../components/ToogleAccordion';
import {
  BoxContainer,
  ButtonsSpace,
  CancelOsBox,
  FlexSpaceBetweenBox,
  LoadingBox,
  SectionBox,
  SubmitBox,
} from '../../components/UI/Box';
import {
  BackButton,
  CancelOSButton,
  FilledButton,
  OutlinedButton,
} from '../../components/UI/Button';
import { GridContainer } from '../../components/UI/Grid';
import { WhiteCircularProgress } from '../../components/UI/Typography';
import {
  IconApartmentMS,
  IconArrowCircleLeftMS,
  IconCalendarTodayMS,
  IconCancelMS,
  IconFactCheckMS,
  IconListAltMS,
  IconLocationCityMS,
  IconLocationOnMS,
  IconPhotoLibraryMS,
} from '../../constants/icons';
import { Constants, getTourSteps } from '../../constants/sampleCreation';
import { driverConfig } from '../../helpers/driver/config';
import { useAccordion } from '../../hooks/useAccordion';
import { useCancelWorkOrder } from '../../hooks/useCancelWorkOrder';
import { useChangeStatus } from '../../hooks/useChangeStatus';
import useGeneral from '../../hooks/useGeneral';
import { useTour } from '../../hooks/useTour';
import { AddressDialog } from './AddressDialog';
import ElementsManagemnt from './ElementsManagement';
import useSampleCreation from './hooks';
import { Sample } from './Sample';
import { SampleHeader } from './SampleHeader';
import { ExtrapolationsErrors, FlexBox } from './styles';

export default function SampleCreation(): JSX.Element {
  const isBank = process.env.REACT_APP_IS_BANK === 'true';

  const [stepTour, setStepTour] = useState<DriveStep[]>();
  const [tourHasStarted, setTourHasStarted] = useState(false);

  const { navigateHome, osId } = useGeneral();
  const {
    handleStatus,
    loadingApprove,
    setLoadingApprove,
    handlePreviousStatus,
    loadingPreviousStatus,
    dontUseAvmLoading,
    setDontUseAvmLoading,
  } = useChangeStatus();
  const { handleCancelWorkOrder } = useCancelWorkOrder();
  const {
    expandOne,
    setExpandOne,
    expandTwo,
    setExpandTwo,
    expandThree,
    setExpandThree,
    expandFour,
    setExpandFour,
    expandFive,
    setExpandFive,
    expandSix,
    setExpandSix,
    expandSeven,
    setExpandSeven,
    expandAll,
    setExpandAll,
    toogleAccordions,
  } = useAccordion();
  const {
    handleApproveConservation,
    checkApprovedSample,
    handleSearchSample,
    propertyData,
    disableInfo,
    age,
    setAge,
    conservation,
    setConservation,
    buildingStandard,
    setBuildingStandard,
    loading,
    sampleData,
    completeElements,
    incompleteElements,
    pinPlace,
    showMap,
    showModal,
    evaluationTypeIsFactors,
    getSample,
    disapprovedElements,
    setDisapprovedElements,
    minElements,
    rooms,
    handleDeleteSample,
    setSupplyFactor,
    isSupplyEditionDisabled,
    supplyFactor,
    setIsSupplyEditionDisabled,
    extrapolationsErrors,
    isConfirmChangesLoading,
    showAddressModal,
    setShowAddressModal,
    handleNewSupplyFactor,
    loadingDelete,
    loadingPage,
    enableEditing,
    setEnableEditing,
    client,
    setClient,
    solicitor,
    setSolicitor,
    goal,
    setGoal,
    propertyFunction,
    setPropertyFunction,
    propertyUse,
    setPropertyUse,
    handleFileUpload,
    registerFileName,
    iptuFileName,
    downloadIptu,
    downloadRegister,
    registerNumber,
    setRegisterNumber,
    concept,
    setConcept,
    zone,
    setZone,
    judicialDistrict,
    setJudicialDistrict,
    registrationUf,
    setRegistrationUf,
    propertyRooms,
    setPropertyRooms,
    toilets,
    setToilets,
    suites,
    setSuites,
    handleSubmit,
    submitLoading,
    page,
    setPage,
    lastElementEdited,
    setLastElementEdited,
    maxRadius,
    setMaxRadius,
    parkingError,
    factorsMax,
    inferenceMax,
    completedSections,
  } = useSampleCreation();

  const {
    tourCompletion,
    setTourCompletion,
    setTutorialStep,
    setToBeContinued,
    setTourOn,
    isTourOn,
    tourSelection,
    setTourSelection,
    driveIsActive,
    setDriveIsActive,
  } = useTour();

  useEffect(() => {
    if (lastElementEdited) {
      setPage(lastElementEdited);
      setLastElementEdited(undefined);
    }
  }, [sampleData]);

  useEffect(() => {
    let steps: DriveStep[] = [];
    if (sampleData === undefined) {
      steps = getTourSteps(false);
    } else if (sampleData.length) {
      steps = getTourSteps(true);
    }
    setStepTour(steps);
  }, [sampleData]);

  const driverObj = driver({
    ...driverConfig,
    steps: stepTour,
    onPrevClick: () => {
      const activeIndex = driverObj.getActiveIndex();
      if (activeIndex === 12) {
        const closeIcon = document.getElementById('close-icon');
        closeIcon?.click();
      }
      if (activeIndex === 1 && sampleData?.length) {
        driverObj.movePrevious();
      }
      driverObj.movePrevious();
    },
    onNextClick: () => {
      const activeIndex = driverObj.getActiveIndex() || 0;
      if (activeIndex === 2 && !sampleData?.length) {
        driverObj.destroy();
        setTourCompletion((prev) => {
          const newTourCompletion = { ...prev };

          newTourCompletion.sampleCreate[0].step = activeIndex;
          return newTourCompletion;
        });
      }
      if (activeIndex === 0 && sampleData?.length) {
        driverObj.moveNext();
      }
      if (driverObj.isLastStep()) {
        setTourCompletion((prev) => {
          const newTourCompletion = { ...prev };
          newTourCompletion.sampleCreate[0].step = activeIndex;
          newTourCompletion.sampleCreate[0].complete = true;
          return newTourCompletion;
        });
        setToBeContinued(false);
        setTourSelection(false);
        setDriveIsActive(false);
      }
      driverObj.moveNext();
    },
    onCloseClick: () => {
      setTourSelection(false);
      setToBeContinued(false);
      setDriveIsActive(false);
      driverObj.destroy();
    },
    onDestroyStarted: () => {
      setTourSelection(false);
      setToBeContinued(false);
      setDriveIsActive(false);
      driverObj.destroy();
    },
  });

  useEffect(() => {
    if (
      !tourCompletion.sampleCreate[0].complete &&
      !tourCompletion.skipTour.sampleCreate
    ) {
      setTutorialStep(StepTour.SAMPLECREATESTART);
      if (
        !driveIsActive &&
        !tourHasStarted &&
        !loadingPage &&
        tourCompletion.sampleCreate[0].step === 0
      ) {
        setTourOn(true);
        setTourHasStarted(true);
      } else if (
        !isTourOn &&
        driveIsActive &&
        !loadingPage &&
        !showAddressModal
      ) {
        if (tourCompletion.sampleCreate[0].step > 0) {
          if (sampleData?.length) {
            driverObj.moveTo(tourCompletion.sampleCreate[0].step + 1);
          }
        } else {
          driverObj.drive();
        }
      }
    }
    if (tourSelection && driveIsActive && !isTourOn) {
      setTutorialStep(StepTour.SAMPLECREATESTART);
      setTimeout(() => {
        driverObj.drive();
      }, 250);
    }

    if (showAddressModal) {
      driverObj.destroy();
    }
  }, [tourCompletion, driveIsActive, isTourOn, loadingPage, showAddressModal]);

  useEffect(() => {
    if (
      expandOne &&
      expandTwo &&
      expandThree &&
      expandFour &&
      expandFive &&
      expandSix &&
      expandSeven
    ) {
      setExpandAll(true);
    } else {
      setExpandAll(false);
    }
  }, [
    expandOne,
    expandTwo,
    expandThree,
    expandFour,
    expandFive,
    expandSix,
    expandSeven,
  ]);

  useEffect(() => {
    setExpandThree(true);
    setExpandSeven(true);
  }, []);

  const showSchedulingAccordion =
    propertyData?.evaluation_type === EvaluationType.SIMPFACTORS ||
    propertyData?.evaluation_type === EvaluationType.SIMPINFERENCES;

  return (
    <GridContainer>
      <BackButton onClick={navigateHome}>{IconArrowCircleLeftMS}</BackButton>
      <BoxContainer component="form" id="sample" onSubmit={handleSubmit}>
        <Title
          osNumber={propertyData?.reference_number || 0}
          title={Constants.sampleCreation}
          createdAt={propertyData?.created_at}
        />
        {loadingPage || !propertyData ? (
          <LoadingBox>
            <CircularProgress />
          </LoadingBox>
        ) : (
          <>
            <FlexSpaceBetweenBox>
              <FlexBox>
                {!isBank && (
                  <CancelOsBox>
                    <ConfirmationDialog
                      text={Constants.cancelOsText}
                      button={
                        <CancelOSButton>
                          {IconCancelMS}
                          {Constants.cancelOs}
                        </CancelOSButton>
                      }
                      model="error"
                      modalCallback={handleCancelWorkOrder}
                    />
                  </CancelOsBox>
                )}
                <EditWorkOrder
                  edit={enableEditing}
                  setEdit={setEnableEditing}
                />
              </FlexBox>
              <FlexSpaceBetweenBox gap="10px">
                {isBank && propertyData?.worker_company_id !== null && (
                  <MessagesDialog osId={osId} />
                )}
                <AttachFilesDialog propertyData={propertyData} osId={osId} />
              </FlexSpaceBetweenBox>
            </FlexSpaceBetweenBox>
            <WorkOrderProgressBar complete>
              <>
                {enableEditing ? (
                  <ClientDataEdit
                    propertyData={propertyData}
                    client={client}
                    setClient={setClient}
                    solicitor={solicitor}
                    setSolicitor={setSolicitor}
                    goal={goal}
                    setGoal={setGoal}
                    propertyFunction={propertyFunction}
                    setPropertyFunction={setPropertyFunction}
                    propertyUse={propertyUse}
                    setPropertyUse={setPropertyUse}
                  />
                ) : (
                  <ClientData propertyData={propertyData} />
                )}
                <ToogleAccordion expand={expandAll} toogle={toogleAccordions} />
              </>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar complete>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyData}
                  icon={IconApartmentMS}
                  openAccordion={expandOne}
                  setOpenAccordion={setExpandOne}
                />
                {expandOne &&
                  (enableEditing ? (
                    <PropertyDataEdit
                      propertyData={propertyData}
                      handleFileUpload={handleFileUpload}
                      registerFileName={registerFileName}
                      iptuFileName={iptuFileName}
                      downloadIptu={downloadIptu}
                      downloadRegister={downloadRegister}
                      registerNumber={registerNumber}
                      setRegisterNumber={setRegisterNumber}
                      concept={concept}
                      setConcept={setConcept}
                      zone={zone}
                      setZone={setZone}
                      judicialDistrict={judicialDistrict}
                      setJudicialDistrict={setJudicialDistrict}
                      registrationUf={registrationUf}
                      setRegistrationUf={setRegistrationUf}
                    />
                  ) : (
                    <PropertyData propertyData={propertyData} />
                  ))}
              </SectionBox>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar complete>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyAddress}
                  icon={IconLocationOnMS}
                  openAccordion={expandTwo}
                  setOpenAccordion={setExpandTwo}
                />
                {expandTwo && (
                  <PropertyAddressWithMaps
                    checkLocation
                    propertyData={propertyData}
                  />
                )}
              </SectionBox>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar complete={completedSections.propertyDetails}>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyDetails}
                  icon={IconLocationCityMS}
                  openAccordion={expandThree}
                  setOpenAccordion={setExpandThree}
                />
                {expandThree && (
                  <>
                    {enableEditing ? (
                      <PropertyInfoEdit
                        propertyData={propertyData}
                        propertyRooms={propertyRooms}
                        setPropertyRooms={setPropertyRooms}
                        toilets={toilets}
                        setToilets={setToilets}
                        suites={suites}
                        setSuites={setSuites}
                      />
                    ) : (
                      <PropertyInfo propertyData={propertyData} isSample />
                    )}
                    <ApproveConservation
                      propertyData={propertyData}
                      disableInfo={disableInfo}
                      age={age}
                      setAge={setAge}
                      conservation={conservation}
                      setConservation={setConservation}
                      buildingStandard={buildingStandard}
                      setBuildingStandard={setBuildingStandard}
                      handleApproveConservation={handleApproveConservation}
                    />
                  </>
                )}
              </SectionBox>
            </WorkOrderProgressBar>
            {showSchedulingAccordion && (
              <WorkOrderProgressBar complete>
                <SectionBox>
                  <AccordionTitle
                    title={Constants.scheduleInfo}
                    icon={IconCalendarTodayMS}
                    openAccordion={expandFour}
                    setOpenAccordion={setExpandFour}
                  />
                  {expandFour && (
                    <ScheduleInfo
                      inspectionData={propertyData.inspection}
                      rooms={rooms}
                    />
                  )}
                </SectionBox>
              </WorkOrderProgressBar>
            )}
            <WorkOrderProgressBar complete>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyPhotos}
                  icon={IconPhotoLibraryMS}
                  openAccordion={expandFive}
                  setOpenAccordion={setExpandFive}
                />
                {expandFive && (
                  <Box>
                    {propertyData.inspection && rooms.length > 0 ? (
                      <Rooms
                        navigationPath={`${osId}/inspection/${propertyData.inspection.id}/rooms`}
                        rooms={rooms}
                        osId={osId}
                        inspectionId={propertyData.inspection.id}
                      />
                    ) : (
                      <Typography>{Constants.noPhotos}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar complete>
              <SectionBox>
                <AccordionTitle
                  title={Constants.form}
                  icon={IconListAltMS}
                  openAccordion={expandSix}
                  setOpenAccordion={setExpandSix}
                />
                {expandSix && (
                  <FormResponse
                    questionForm={propertyData.inspection?.form_response}
                  />
                )}
              </SectionBox>
            </WorkOrderProgressBar>
            <WorkOrderProgressBar
              complete={completedSections.sample}
              lastAccordion
            >
              <SectionBox>
                <AccordionTitle
                  title={Constants.samples}
                  icon={IconFactCheckMS}
                  openAccordion={expandSeven}
                  setOpenAccordion={setExpandSeven}
                />
                {expandSeven && (
                  <>
                    <SampleHeader
                      supplyFactor={supplyFactor}
                      setSupplyFactor={setSupplyFactor}
                      isSupplyEditionDisabled={isSupplyEditionDisabled}
                      setIsSupplyEditionDisabled={setIsSupplyEditionDisabled}
                      sampleData={sampleData}
                      handleNewSupplyFactor={handleNewSupplyFactor}
                      loadingDelete={loadingDelete}
                      maxRadius={maxRadius}
                      setMaxRadius={setMaxRadius}
                      evaluationTypeIsFactors={evaluationTypeIsFactors}
                      factorsMax={factorsMax}
                      inferenceMax={inferenceMax}
                      handleSearchSample={handleSearchSample}
                      handleDeleteSample={handleDeleteSample}
                      loading={loading}
                    />
                    {!loading && extrapolationsErrors.length > 0 && (
                      <ExtrapolationsErrors>
                        {extrapolationsErrors}
                        <p>{Constants.extrapolation}</p>
                      </ExtrapolationsErrors>
                    )}
                    {loading ? (
                      <LoadingBox>
                        <CircularProgress />
                      </LoadingBox>
                    ) : (
                      sampleData &&
                      sampleData.length > 0 &&
                      completeElements &&
                      incompleteElements && (
                        <Sample
                          disapprovedElements={disapprovedElements}
                          setDisapprovedElements={setDisapprovedElements}
                          propertyData={propertyData}
                          sampleData={sampleData}
                          completeElements={completeElements}
                          incompleteElements={incompleteElements}
                          pinPlace={pinPlace}
                          showMap={showMap}
                          updateSample={getSample}
                          minElements={minElements}
                          page={page}
                          setPage={setPage}
                          setLastElementEdited={setLastElementEdited}
                        />
                      )
                    )}
                    <ElementsManagemnt
                      sampleData={sampleData}
                      osId={osId}
                      updateSample={getSample}
                      propertyData={propertyData}
                    />
                  </>
                )}
              </SectionBox>
            </WorkOrderProgressBar>
            <SubmitBox>
              <PeptDialog
                osId={osId}
                referenceNumber={propertyData.reference_number}
              />
              <ButtonsSpace>
                <ConfirmationDialog
                  text={Constants.previousStatusText.replace(
                    '**',
                    `${propertyData.reference_number}`
                  )}
                  button={
                    <FilledButton
                      width={isBank ? 'ms' : undefined}
                      model="warning"
                      disabled={loadingPreviousStatus}
                    >
                      {loadingPreviousStatus ? (
                        <WhiteCircularProgress size={22} />
                      ) : (
                        Constants.previousStatus
                      )}
                    </FilledButton>
                  }
                  modalCallback={() =>
                    handlePreviousStatus(
                      osId,
                      propertyData.reference_number,
                      Constants.previousStatusName
                    )
                  }
                />
                {enableEditing ? (
                  <FilledButton
                    width={isBank ? 'ms' : undefined}
                    form="sample"
                    type="submit"
                    disabled={submitLoading}
                  >
                    {submitLoading ? (
                      <CircularProgress size={22} />
                    ) : (
                      Constants.confirmEdit
                    )}
                  </FilledButton>
                ) : showModal ? (
                  <ConfirmationDialog
                    width={isBank ? 'ms' : undefined}
                    loading={loadingApprove}
                    title={Constants.send}
                    text={Constants.changeStatus.replace(
                      '**',
                      `${propertyData.reference_number}`
                    )}
                    modalCallback={() => {
                      setLoadingApprove(true);
                      handleStatus(osId, propertyData.reference_number);
                    }}
                    errorMessage={
                      parkingError ? Constants.parkingErrorMessage : undefined
                    }
                  />
                ) : (
                  <OutlinedButton
                    width={isBank ? 'ms' : undefined}
                    onClick={checkApprovedSample}
                    disabled={!!extrapolationsErrors.length}
                  >
                    {isConfirmChangesLoading ? (
                      <CircularProgress size={22} />
                    ) : (
                      Constants.confirmChanges
                    )}
                  </OutlinedButton>
                )}
                {isBank && (
                  <ConfirmationDialog
                    width="ms"
                    loading={dontUseAvmLoading}
                    title={Constants.dontUseAvm}
                    text={Constants.changeStatusAndDontUseAvm.replace(
                      '**',
                      `${propertyData.reference_number}`
                    )}
                    modalCallback={() => {
                      setDontUseAvmLoading(true);
                      handleStatus(osId, propertyData.reference_number, true);
                    }}
                  />
                )}
              </ButtonsSpace>
            </SubmitBox>
          </>
        )}
      </BoxContainer>
      <AddressDialog show={showAddressModal} setShow={setShowAddressModal} />
    </GridContainer>
  );
}
