import {
  Box,
  ButtonBase,
  TableCell,
  TableContainer,
  tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  width: '100%',
  maxHeight: '500px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '10px',
    height: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export const Cell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: '600',
    fontSize: '18px',
    backgroundColor: '#DCC7E6',
    borderRight: '1px solid #C087DC',
    '&:last-child': {
      borderRight: '0',
    },
    borderBottom: `1px solid #DCC7E6`,
  },
}));

export const StyledTableCell = styled(TableCell)(() => ({
  color: ' #2E383F',
  padding: '12px',
  fontSize: '18px',
  '&:first-of-type': {
    borderLeft: `1px solid #DCC7E6`,
  },
  '&:last-child': {
    borderRight: `1px solid #DCC7E6`,
    '& span': {
      fontWeight: 'bold',
    },
  },
}));

export const AccordionBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  span: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
}));

export const StyledButton = styled(ButtonBase)(({ theme }) => ({
  textDecoration: 'underline',
  fontSize: 16,
  fontWeight: 600,
  color: theme.palette.primary.main,
}));

export const CoverageBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '30%',
  padding: '8px 12px',
}));
