/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { sampleAPI, workOrderAPI } from '../../../api';
import { EvaluationType, Status, StatusCode } from '../../../api/enumerations';
import { RoomData } from '../../../api/rooms/types';
import { ElementData } from '../../../api/sample/types';
import {
  AvmFactorsData,
  AvmInferenceData,
  WorkOrderData,
} from '../../../api/workOrders/types';
import { getErrorMessage } from '../../../helpers';
import useGeneral from '../../../hooks/useGeneral';
import { usePropertyData } from '../../../hooks/usePropertyData';
import { useRooms } from '../../../hooks/useRooms';
import useSnackbar from '../../../hooks/useSnackbar';

interface RevisionHook {
  propertyData: WorkOrderData | undefined;
  sampleData: ElementData[] | undefined;
  hasAvmReport: boolean;
  rooms: RoomData[];
  openErrorReport: boolean;
  setOpenErrorReport: (value: boolean) => void;
  loadingPage: boolean;
  downloadPdf: string;
  downloadMemorial: string;
}

const useRevision = (): RevisionHook => {
  const [hasAvmReport, setHasAvmReport] = useState(false);
  const [sampleData, setSampleData] = useState<ElementData[]>();
  const [openErrorReport, setOpenErrorReport] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const [downloadPdf, setDownloadPdf] = useState('');
  const [downloadMemorial, setDownloadMemorial] = useState('');

  const { propertyData, getDataCallback } = usePropertyData({
    status: Status.REVISION,
  });
  const { osId } = useGeneral();
  const { handleRooms, rooms } = useRooms();
  const { handleSnackbar } = useSnackbar();

  const isBank = process.env.REACT_APP_IS_BANK === 'true';

  const getSample = async (): Promise<void> => {
    let sample = false;
    let isFactors = false;

    if (propertyData) {
      if (isBank) {
        setDownloadPdf('');
        setDownloadMemorial('');
      }
      if (propertyData.inspection) {
        handleRooms(propertyData.inspection.id);
      }
      const avmData = propertyData.avm_report as
        | AvmInferenceData
        | AvmFactorsData
        | null;
      if (avmData && avmData.scores) {
        setHasAvmReport(true);
      }
      if (
        propertyData.evaluation_type === EvaluationType.AUTOFACTORS ||
        propertyData.evaluation_type === EvaluationType.SIMPFACTORS
      ) {
        isFactors = true;
      }
      if (propertyData.samples) {
        sample = true;
      }

      setLoadingPage(false);
    }
    if (sample) {
      try {
        const response = await sampleAPI.getSample(osId, 1, 105);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (!response.data) {
          throw new Error('A amostra não pode ser carregada.');
        }

        if (isFactors) {
          const filteredSamples: ElementData[] = response.data.items.filter(
            (data) => data.is_utilized
          );
          setSampleData(filteredSamples);
          return;
        }
        setSampleData(response.data.items);
      } catch (error) {
        handleSnackbar(getErrorMessage(error), true);
      }
    }
  };

  const handleSamplesReport = async (): Promise<void> => {
    try {
      const response = await workOrderAPI.generateSamplePDF(osId);

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error(response.detail.description);
      }

      getDataCallback();
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    }
  };

  useEffect(() => {
    getSample();
    if (propertyData && !propertyData.samples_report_pdf) {
      handleSamplesReport();
    }
  }, [propertyData]);

  return {
    propertyData,
    sampleData,
    hasAvmReport,
    rooms,
    openErrorReport,
    setOpenErrorReport,
    loadingPage,
    downloadPdf,
    downloadMemorial,
  };
};

export default useRevision;
