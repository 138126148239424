import { FormLabel, Grid } from '@mui/material';
import { useEffect } from 'react';

import { useReportCompletion } from '../../../../hooks/useReportCompletion';
import useReport from '../../../../pages/Report/hooks';
import { InspectionSelectedPhotos } from '../../../../pages/Report/InspectionSelectedPhotos';

export function Pictures(): JSX.Element {
  const { propertyData, selectedPictures, removeSelectedPicture, rooms } =
    useReport();
  const { toggleCompletion } = useReportCompletion();

  useEffect(() => {
    if (selectedPictures.length) {
      toggleCompletion('images', true);
    }
  }, [selectedPictures, toggleCompletion]);
  return (
    <Grid container>
      <Grid item xs={12}>
        {propertyData?.inspection?.id && rooms.length > 0 ? (
          <InspectionSelectedPhotos
            selectedPhotos={selectedPictures}
            inspectionId={propertyData?.inspection?.id}
            removeSelection={removeSelectedPicture}
          />
        ) : (
          <FormLabel>Esta OS não possui imagens</FormLabel>
        )}
      </Grid>
    </Grid>
  );
}
