import {
  CondominiumInformationProps,
  ImprovementsProps,
} from '../../../../api/workOrders/types';

export const InitialStateImprovements: ImprovementsProps[] = [
  {
    name: '',
    area: 0,
    finishing_standard: '',
    age: 0,
    preservation_state: '',
    observations: '',
  },
];
export const CondominiumStateDefault: CondominiumInformationProps[] = [
  {
    type: '',
    framing: '',
    wall_covering: '',
    number_of_floors: 0,
    number_of_units_per_floor: 0,
    appraisal_position: '',
    age: 0,
    number_of_blocks: 0,
    number_of_elevators: 0,
    use_type: '',
    number_of_units: 0,
    finishing_standard: '',
    preservation_state: '',
    location_status_level: '',
    area: 0,
    main_facade_components: [],
    fire_protection: [],
    other_fire_protections: '',
    infrastructure_services_and_equipments_components: [],
  },
];
