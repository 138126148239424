/* eslint-disable max-len */
import {
  Box,
  ButtonBase,
  IconButton,
  MenuItem,
  styled,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

export const ReportTitle = styled(Typography)(({ theme }) => ({
  fontSize: '21px',
  fontWeight: '600',
  color: '#707070',
  textTransform: 'uppercase',
  borderBottom: `2px solid ${theme.palette.primary.main}`,
  width: 'fit-content',
}));

export const OutlinedButton = styled(ButtonBase)(() => ({
  width: '111px',
  height: '56px',
  border: '1px solid #B038FA',
  borderRadius: '12px',

  '& svg, span': {
    color: '#B038FA',
    fontSize: '32px',
  },

  '&:hover': {
    background: '#B038FA',
    transition: 'background 0.5s',

    '& svg, span': {
      color: '#FFFFFF',
    },
  },
  '&:disabled': {
    opacity: 0.7,
    cursor: 'not-allowed',

    '& svg, span': {
      opacity: 0.7,
    },
  },
}));

export const RemovePictureButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  color: 'white',
  background: '#B038FA 0% 0% no-repeat padding-box;',
  padding: '4px',
  width: '24px',
  height: '24px',
  zIndex: 99,

  '& span': {
    fontSize: 18,
  },

  '&:hover': {
    background: '#B038FA',
    opacity: 0.7,
    transition: 'opacity 0.3s',
  },
}));

export const ContainedButton = styled(ButtonBase)(({ theme }) => ({
  background: '#9150B2',
  width: '50%',
  height: '48px',
  fontSize: '16px',
  fontWeight: '600',

  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  color: theme.palette.common.white,

  borderRadius: 8,
}));

export const InputPictureContainer = styled(Box)(() => ({
  border: '1px dashed #B038FA',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '150px',
  borderRadius: 4,
}));

export const AttachmentList = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px solid #ccc',
  paddingLeft: '8px',

  '& p': {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },

  '& span': {
    color: theme.palette.primary.main,
  },
}));

export const StyledTableRowBody = styled(TableRow)(() => ({
  '& input': {
    border: 'none',
    outline: 'none',
    width: '100%',
  },
}));

export const StyledTableCellBody = styled(TableCell)(() => ({
  border: '1px solid #CCC',
  paddingRight: 0,
  paddingLeft: 0,
  // height: 80,
  flexWrap: 'wrap',

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    outline: 'none',
  },
}));

export const StyledSelect = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& .MuiInputBase-root': {
    borderRadius: '16px',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
    fontSize: '2rem',
  },
}));

export const StyledMenuItem = styled(MenuItem)(() => ({
  '&:hover': { backgroundColor: '#d485eed1' },
  '&:first-of-type': {
    color: '#7c7c7c',
    '&.Mui-selected': { backgroundColor: '#EDEDED' },
    '&:hover': {
      backgroundColor: '#EDEDED',
      cursor: 'auto',
    },
  },
}));

export const StyledTextField = styled(TextField)(() => ({
  width: '100%',
  '& .MuiInputBase-root': {
    whiteSpace: 'normal',
    borderRadius: '16px',
    textOverflow: 'clip',
    display: 'block',
  },
  'input:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0px 1000px #F5F5F4 inset',
  },
  '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button':
    { '-webkit-appearance': 'none', margin: 0 },
  '& input[type=number]': { '-moz-appearance': 'textfield' },
}));

export const EditButton = styled(ButtonBase)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.dark}`,
  padding: '8px',
  borderRadius: '8px',
  '& span': {
    fontSize: '22px',
    color: theme.palette.primary.dark,
  },
  '&:hover': {
    background: theme.palette.primary.dark,
    '& span': {
      color: theme.palette.common.white,
    },
  },

  '&:disabled': {
    border: `1px solid #CCC`,
    '& span': {
      color: '#CCC',
    },
  },
}));

export const CancelButton = styled(ButtonBase)(({ theme }) => ({
  border: `1px solid ${theme.palette.common.danger}`,
  padding: '8px',
  borderRadius: '8px',
  '& span': {
    fontSize: '22px',
    color: theme.palette.common.danger,
  },
  '&:hover': {
    background: theme.palette.common.danger,
    '& span': {
      color: theme.palette.common.white,
    },
  },

  '&:disabled': {
    border: `1px solid #CCC`,
    '& span': {
      color: '#CCC',
    },
  },
}));
