import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, TextFieldProps, Tooltip } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useContext, useEffect, useState } from 'react';

import { patchStatisticalReport } from '../../../../api/workOrders';
import {
  StaticalReportData,
  WorkOrderData,
} from '../../../../api/workOrders/types';
import { IconSaveMS } from '../../../../constants/icons';
import { GlobalContext } from '../../../../context/global';
import { getErrorMessage } from '../../../../helpers';
import useGeneral from '../../../../hooks/useGeneral';
import { useReportCompletion } from '../../../../hooks/useReportCompletion';
import Snackbar from '../../../Snackbar';
import { StyledTextField } from '../../ReportInfo/ArbitraryValue/styles';
import { EditButton, StyledMenuItem, StyledSelect } from '../styles';

interface EvaluationMethodologyProps {
  type: string;
  methodology: string;
  methodology_name: string;
  f_calculus_less_than_20: boolean | undefined;
  reasoning_level: string;
  precision_level: string;
  determination_coefficient_less_than_0_64: boolean | undefined;
  number_of_independent_variables: number;
  number_of_data: number;
  samples_reference_research_start_date: string;
  samples_reference_research_end_date: string;
  have_variable_with_significance_level_greater_than_30_percent:
    | boolean
    | undefined;
  have_extrapolated_variable: boolean | undefined;
  have_micronumerosity: boolean | undefined;
  have_outliers: boolean | undefined;
  is_value_from_central_estimate: boolean | undefined;
  justification: string;
  model_equation: string;
  observations: string;
}

interface MethodologyProps {
  propertyData: WorkOrderData | undefined;
}

export function Methodology({ propertyData }: MethodologyProps): JSX.Element {
  const [allFilled, setAllFilled] = useState(false);
  const [initialPeriodDate, setInitialPeriodDate] = useState<Dayjs | null>(
    null
  );
  const [finishPeriodDate, setFinishPeriodDate] = useState<Dayjs | null>(null);
  const [inputs, setInputs] = useState<EvaluationMethodologyProps>({
    type: '',
    methodology: '',
    methodology_name: '',
    f_calculus_less_than_20: Boolean(undefined),
    reasoning_level: '',
    precision_level: '',
    determination_coefficient_less_than_0_64: Boolean(undefined),
    number_of_independent_variables: 0,
    number_of_data: 0,
    samples_reference_research_start_date: '',
    samples_reference_research_end_date: '',
    have_variable_with_significance_level_greater_than_30_percent:
      Boolean(undefined),
    have_extrapolated_variable: Boolean(undefined),
    have_micronumerosity: Boolean(undefined),
    have_outliers: Boolean(undefined),
    is_value_from_central_estimate: Boolean(undefined),
    justification: '',
    model_equation: '',
    observations: '',
  });
  const [isDisabled, setIsDisabled] = useState(true);

  const { osId } = useGeneral();
  const { toggleCompletion } = useReportCompletion();
  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const handleInputChange = (
    field: keyof EvaluationMethodologyProps,
    value: string | number | boolean
  ): void => {
    setInputs((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleStartDateChange = (newDate: Dayjs | null): void => {
    const formattedDate = newDate?.format('YYYY-MM-DD');
    setInitialPeriodDate(dayjs(newDate, 'DD-MM-YYYY'));
    if (formattedDate) {
      setInputs({
        ...inputs,
        samples_reference_research_start_date: formattedDate?.toString(),
      });
    }
  };

  const handleEndDateChange = (newDate: Dayjs | null): void => {
    const formattedDate = newDate?.format('YYYY-MM-DD');
    setFinishPeriodDate(dayjs(formattedDate, 'DD/MM/YYYY'));
    if (formattedDate) {
      setInputs({
        ...inputs,
        samples_reference_research_end_date: formattedDate?.toString(),
      });
    }
  };

  const checkIfAllFieldsAreFilled = (
    object: EvaluationMethodologyProps
  ): boolean => {
    // Verifica se todos os campos do objeto têm valores não vazios
    return Object.values(object).every((value) => value !== '');
  };

  const handleSendJSON = useCallback(async () => {
    try {
      const data: StaticalReportData = {
        evaluation_methodology: inputs,
      };
      const response = await patchStatisticalReport(osId, data);
      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (!data) {
        throw new Error('Algo deu errado, tente novamente');
      }
      toggleCompletion('methodology', true);
    } catch (error) {
      setErrorMessage(true);
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
    }
  }, [
    inputs,
    osId,
    setErrorMessage,
    setOpenSnackbar,
    setSnackbarMessage,
    toggleCompletion,
  ]);

  useEffect(() => {
    if (propertyData?.manual_statistical_report.evaluation_methodology) {
      setInputs(propertyData?.manual_statistical_report.evaluation_methodology);
    }
  }, [propertyData]);

  useEffect(() => {
    setAllFilled(checkIfAllFieldsAreFilled(inputs));
    if (allFilled) {
      toggleCompletion('methodology', true);
      setIsDisabled(false);
    } else {
      toggleCompletion('methodology', false);
      setIsDisabled(true);
    }
  }, [allFilled, inputs, toggleCompletion]);

  return (
    <Grid container rowGap={2} columnGap={2} sx={{}}>
      <Grid item xs={2.5}>
        <StyledSelect
          select
          label="tratamento"
          value={String(inputs.type)}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('type', e.target.value)}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="estatística inferencial">
            inferência
          </StyledMenuItem>
          <StyledMenuItem value="fatores">fatores</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="metodologia de avaliação"
          value={inputs.methodology}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('methodology', e.target.value)}
        >
          <StyledMenuItem value={undefined}>selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="MCDDM">MCDDM</StyledMenuItem>
          <StyledMenuItem value="SMDNO">SMDNO</StyledMenuItem>
          <StyledMenuItem value="outras metodologias">
            outras metodologias
          </StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          label="indicar metodologia"
          value={inputs.methodology_name}
          onChange={(e) =>
            handleInputChange('methodology_name', e.target.value)
          }
        />
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          id=""
          label="F calculado menor que 20?"
          value={String(inputs.f_calculus_less_than_20)}
          select
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) =>
            handleInputChange('f_calculus_less_than_20', e.target.value)
          }
        >
          <StyledMenuItem value={undefined}>selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="true">sim</StyledMenuItem>
          <StyledMenuItem value="false">não</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Tooltip arrow placement="top" title="grau de fundamentação">
        <Grid item xs={2}>
          <StyledSelect
            id=""
            select
            label="grau de fundamentação"
            value={inputs.reasoning_level}
            SelectProps={{
              IconComponent: ExpandMoreIcon,
            }}
            onChange={(e) =>
              handleInputChange('reasoning_level', e.target.value)
            }
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="I">I</StyledMenuItem>
            <StyledMenuItem value="II">II</StyledMenuItem>
            <StyledMenuItem value="III">III</StyledMenuItem>
          </StyledSelect>
        </Grid>
      </Tooltip>
      <Tooltip arrow placement="top" title="grau de precisão">
        <Grid item xs={1.5}>
          <StyledSelect
            id=""
            select
            label="grau de precisão"
            value={inputs.precision_level}
            SelectProps={{
              IconComponent: ExpandMoreIcon,
            }}
            onChange={(e) =>
              handleInputChange('precision_level', e.target.value)
            }
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="I">I</StyledMenuItem>
            <StyledMenuItem value="II">II</StyledMenuItem>
            <StyledMenuItem value="III">III</StyledMenuItem>
          </StyledSelect>
        </Grid>
      </Tooltip>
      <Tooltip
        arrow
        placement="top"
        title="o coeficiente de determinação está abaixo de 0,64?"
      >
        <Grid item xs={4}>
          <StyledSelect
            select
            value={inputs.determination_coefficient_less_than_0_64}
            label="o coeficiente de determinação está abaixo de 0,64?"
            SelectProps={{
              IconComponent: ExpandMoreIcon,
            }}
            onChange={(e) =>
              handleInputChange(
                'determination_coefficient_less_than_0_64',
                e.target.value
              )
            }
          >
            <StyledMenuItem value={undefined}>
              selecione uma opção
            </StyledMenuItem>
            <StyledMenuItem value="true">sim</StyledMenuItem>
            <StyledMenuItem value="false">não</StyledMenuItem>
          </StyledSelect>
        </Grid>
      </Tooltip>
      <Tooltip
        arrow
        placement="top"
        title="quantidade de variáveis independentes adotadas no modelo?"
      >
        <Grid item xs={4}>
          <StyledTextField
            label="quantidade de variáveis independentes adotadas no modelo?"
            value={inputs.number_of_independent_variables}
            type="number"
            onChange={(e) =>
              handleInputChange(
                'number_of_independent_variables',
                Number(e.target.value)
              )
            }
          />
        </Grid>
      </Tooltip>
      <Tooltip
        arrow
        placement="top"
        title="quantidade de dados utilizados no modelo?"
      >
        <Grid item xs={3.5}>
          <StyledTextField
            label="quantidade de dados utilizados no modelo?"
            value={inputs.number_of_data}
            type="number"
            onChange={(e) =>
              handleInputChange('number_of_data', Number(e.target.value))
            }
          />
        </Grid>
      </Tooltip>
      <Tooltip
        arrow
        placement="top"
        title="período inicial de referência de pesquisa das amostras?"
      >
        <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="período inicial de referência de pesquisa das amostras?"
              value={initialPeriodDate}
              format="DD-MM-YYYY"
              slots={{
                textField:
                  StyledTextField as React.ComponentType<TextFieldProps>,
              }}
              onChange={handleStartDateChange}
            />
          </LocalizationProvider>
        </Grid>
      </Tooltip>
      <Tooltip
        arrow
        placement="top"
        title="período final de referência de pesquisa das amostras?"
      >
        <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="período final de referência de pesquisa das amostras?"
              value={finishPeriodDate}
              format="DD-MM-YYYY"
              slots={{
                textField:
                  StyledTextField as React.ComponentType<TextFieldProps>,
              }}
              onChange={handleEndDateChange}
            />
          </LocalizationProvider>
        </Grid>
      </Tooltip>
      <Tooltip
        arrow
        placement="top"
        title="a significância de uma das variáveis é maior que 30%?"
      >
        <Grid item xs={3.5}>
          <StyledSelect
            id=""
            label="a significância de uma das variáveis é maior que 30%?"
            value={String(
              inputs.have_variable_with_significance_level_greater_than_30_percent
            )}
            select
            SelectProps={{
              IconComponent: ExpandMoreIcon,
            }}
            onChange={(e) =>
              handleInputChange(
                'have_variable_with_significance_level_greater_than_30_percent',
                e.target.value
              )
            }
          >
            <StyledMenuItem value={undefined}>
              selecione uma opção
            </StyledMenuItem>
            <StyledMenuItem value="true">sim</StyledMenuItem>
            <StyledMenuItem value="false">não</StyledMenuItem>
          </StyledSelect>
        </Grid>
      </Tooltip>
      <Grid item xs={4}>
        <StyledSelect
          id=""
          label="houve extrapolação de alguma variável?"
          value={String(inputs.have_extrapolated_variable)}
          select
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) =>
            handleInputChange('have_extrapolated_variable', e.target.value)
          }
        >
          <StyledMenuItem value={undefined}>selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="true">sim</StyledMenuItem>
          <StyledMenuItem value="false">não</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Tooltip
        arrow
        placement="top"
        title="o modelo atende a micronumerosidade das variáveis?"
      >
        <Grid item xs={4}>
          <StyledSelect
            id=""
            label="o modelo atende a micronumerosidade das variáveis?"
            value={String(inputs.have_micronumerosity)}
            select
            SelectProps={{
              IconComponent: ExpandMoreIcon,
            }}
            onChange={(e) =>
              handleInputChange('have_micronumerosity', e.target.value)
            }
          >
            <StyledMenuItem value={undefined}>
              selecione uma opção
            </StyledMenuItem>
            <StyledMenuItem value="true">sim</StyledMenuItem>
            <StyledMenuItem value="false">não</StyledMenuItem>
          </StyledSelect>
        </Grid>
      </Tooltip>
      <Grid item xs={3.5}>
        <StyledSelect
          label="o modelo possui Outliers?"
          value={String(inputs.have_outliers)}
          select
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('have_outliers', e.target.value)}
        >
          <StyledMenuItem value={undefined}>selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="true">sim</StyledMenuItem>
          <StyledMenuItem value="false">não</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={4}>
        <StyledSelect
          label="O valor adotado é da estimativa Central?"
          value={String(inputs.is_value_from_central_estimate)}
          select
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) =>
            handleInputChange('is_value_from_central_estimate', e.target.value)
          }
        >
          <StyledMenuItem value={undefined}>selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="true">sim</StyledMenuItem>
          <StyledMenuItem value="false">não</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={4}>
        <StyledTextField
          label="justificativa"
          value={inputs.justification}
          onChange={(e) => handleInputChange('justification', e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          id=""
          label="indicar a equação do modelo adotada?"
          value={inputs.model_equation}
          onChange={(e) => handleInputChange('model_equation', e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          id=""
          label="observação valor adotado"
          value={inputs.observations}
          onChange={(e) => handleInputChange('observations', e.target.value)}
        />
      </Grid>
      <Grid container justifyContent="flex-end" columnGap={2}>
        <Grid item paddingRight={2}>
          <EditButton disabled={isDisabled} onClick={() => handleSendJSON()}>
            {IconSaveMS}
          </EditButton>
        </Grid>
      </Grid>
      {openSnackbar && <Snackbar />}
    </Grid>
  );
}
