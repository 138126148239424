/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress } from '@mui/material';
import CharacterCount from '@tiptap/extension-character-count';
import Color from '@tiptap/extension-color';
import Placeholder from '@tiptap/extension-placeholder';
import TextStyle from '@tiptap/extension-text-style';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useCallback, useContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { StatusCode } from '../../../api/enumerations';
import {
  getUnreadMessagesCount,
  getWorkOrderMessages,
  sendWorkOrderMessage,
  updateMessagesAsRead,
} from '../../../api/hive/workOrders';
import { MessageData } from '../../../api/hive/workOrders/types';
import { IconAttachFileMS, IconCloseMS } from '../../../constants/icons';
import { GlobalContext } from '../../../context/global';
import useErrorMessage from '../../../hooks/useErrorMessage';
import useGeneral from '../../../hooks/useGeneral';
import useSnackbar from '../../../hooks/useSnackbar';
import { CloseIcon } from '../../UI/Button';
import { StyledDialog } from '../../UI/Dialog';
import { MessageField } from './MessageField';
import { MessageReader } from './MessageReader';
import {
  Background,
  ButtonContainer,
  DialogContainer,
  MessageBox,
  MessagesContainer,
  MessagesNumber,
  OpenButton,
  SenderTitle,
  StyledDate,
} from './styles';

import 'dayjs/locale/pt-br';

interface MessagesDialogProps {
  osId: number;
}

export function MessagesDialog({ osId }: MessagesDialogProps): JSX.Element {
  const [reload, setReload] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [firstLoading, setFirstLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [lastCalledPage, setLastCalledPage] = useState(0);
  const [messagesData, setMessagesData] = useState<MessageData[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);

  const messagesPerPage = 5;

  const dialog = useGeneral();
  const { getErrorMessage } = useErrorMessage();
  const { handleSnackbar } = useSnackbar();
  const { company } = useContext(GlobalContext);

  const editorEditable = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: 'Digite uma mensagem',
      }),
      CharacterCount.configure({
        limit: 500,
      }),
      TextStyle,
      Color,
    ],
    content: '',
    editable: true,
  });

  const resetData = (): void => {
    setFirstLoading(true);
    setPage(1);
    setLastCalledPage(0);
    setMessagesData([]);
  };

  const getUnreadCount = useCallback(async () => {
    try {
      const response = await getUnreadMessagesCount(osId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data) {
        setUnreadCount(response.data.count);
        setReload(true);
      }
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    }
  }, [osId]);

  useEffect(() => {
    getUnreadCount();
  }, []);

  const updateUnreadMessages = useCallback(async () => {
    try {
      const response = await updateMessagesAsRead(osId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }
      setUnreadCount(0);
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    }
  }, [osId]);

  useEffect(() => {
    if (dialog.open && unreadCount > 0) {
      updateUnreadMessages();
    }
  }, [dialog.open, unreadCount]);

  const getRequestsCallback = useCallback(async () => {
    if (page === lastCalledPage) {
      setFirstLoading(false);
      setLoadingMore(false);
      setReload(false);
      return;
    }

    try {
      setLoadingMore(true);
      const response = await getWorkOrderMessages(osId, page, messagesPerPage);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.detail.total_pages && response.detail.total_pages > page) {
        setTotalPages(response.detail.total_pages);
      }

      if (response.data) {
        setMessagesData([...messagesData, ...response.data.messages]);
        setLastCalledPage(page);
      }
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    } finally {
      setLoadingMore(false);
      setFirstLoading(false);
      setReload(false);
    }
  }, [page]);

  useEffect(() => {
    if (page !== 1) {
      getRequestsCallback();
    }
  }, [page]);

  useEffect(() => {
    if (reload) {
      resetData();
      getRequestsCallback();
    }
  }, [reload]);

  const formatDateAndTime = (date: string): string => {
    dayjs.extend(utc);
    dayjs.extend(tz);
    dayjs.locale('pt-br');

    const dateTime = dayjs(date).tz('America/Sao_Paulo');
    const formattedDateTime = dateTime.format('DD [de] MMMM [às] HH:mm');

    return formattedDateTime;
  };

  const handleMessage = (): {
    rawText: string | undefined;
    richText: string;
  } => {
    const jsonText = editorEditable?.getJSON();
    const richText = JSON.stringify(jsonText);
    const rawText = editorEditable?.getText();
    return {
      rawText,
      richText,
    };
  };

  const handleSubmitMessage = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      e.stopPropagation();

      setLoadingSubmit(true);

      const messageText = handleMessage();

      if (!messageText.rawText) {
        setLoadingSubmit(false);
        handleSnackbar('Mensagem inválida', true);
        return;
      }

      const data = {
        message: messageText.richText,
      };

      try {
        const response = await sendWorkOrderMessage(osId, data);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        handleSnackbar('Mensagem enviada com sucesso!', false);
        editorEditable?.commands.setContent('');
        resetData();
        getRequestsCallback();
      } catch (error) {
        handleSnackbar(getErrorMessage(error), true);
      } finally {
        setLoadingSubmit(false);
      }
    },
    [editorEditable]
  );

  const closeDialog = (): void => {
    dialog.handleClose();
    editorEditable?.commands.setContent('');
  };

  return (
    <>
      <ButtonContainer>
        <OpenButton disableTouchRipple onClick={dialog.handleOpen}>
          {IconAttachFileMS}
          mensagens
        </OpenButton>
        {unreadCount > 0 && <MessagesNumber>{unreadCount}</MessagesNumber>}
      </ButtonContainer>
      <StyledDialog open={dialog.open} onClose={closeDialog}>
        <DialogContainer>
          <CloseIcon onClick={closeDialog}>{IconCloseMS}</CloseIcon>
          <MessagesContainer id="scrollableDiv">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height={400}
            >
              {firstLoading && messagesData.length === 0 && (
                <CircularProgress size={22} />
              )}
            </Box>
            <InfiniteScroll
              scrollableTarget="scrollableDiv"
              dataLength={messagesData.length}
              next={() => setPage((prev) => prev + 1)}
              hasMore={page < totalPages}
              inverse
              style={{
                display: 'flex',
                flexDirection: 'column-reverse',
                overflowY: 'hidden',
                gap: 12,
              }}
              loader={
                <Box textAlign="center">
                  {loadingMore && <CircularProgress size={22} />}
                </Box>
              }
            >
              {[...messagesData].reverse().map((message) => {
                const messageFromBank =
                  message.sender.company !== company?.name;
                return (
                  <MessageBox
                    key={message.id}
                    alignItems={messageFromBank ? 'start' : 'end'}
                  >
                    <Background>
                      <StyledDate textAlign={messageFromBank ? 'start' : 'end'}>
                        {formatDateAndTime(message.created_at)}
                      </StyledDate>
                      <SenderTitle
                        textAlign={messageFromBank ? 'start' : 'end'}
                      >
                        {message.sender.user !== null
                          ? message.sender.user
                          : ''}
                      </SenderTitle>
                      <MessageReader messageRichText={message.message} />
                    </Background>
                  </MessageBox>
                );
              })}
            </InfiniteScroll>
          </MessagesContainer>
          <MessageField
            handleSubmit={handleSubmitMessage}
            editor={editorEditable}
            loadingSubmit={loadingSubmit}
          />
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
