/* eslint-disable max-len */
import { Grid, TextFieldProps, Tooltip } from '@mui/material';
import {
  DesktopDatePicker,
  DesktopTimePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useCallback, useContext, useEffect, useState } from 'react';

import { patchStatisticalReport } from '../../../../api/workOrders';
import {
  StaticalReportData,
  WorkOrderData,
} from '../../../../api/workOrders/types';
import { IconSaveMS } from '../../../../constants/icons';
import { GlobalContext } from '../../../../context/global';
import { formatPhone, getErrorMessage } from '../../../../helpers';
import useGeneral from '../../../../hooks/useGeneral';
import { useReportCompletion } from '../../../../hooks/useReportCompletion';
import { StyledTextField } from '../../../../pages/Agenda/styles';
import Snackbar from '../../../Snackbar';
import { EditButton, StyledTextField as TextField } from '../styles';

dayjs.extend(utc);

interface ReportInspectionProps {
  contact_name: string;
  contact_phone: string;
  company_name_and_phone: string;
  contact_date: string;
  inspection_schedule_datetime: string;
  inspection_date: string;
  inspection_start_time: string;
  inspection_end_time: string;
  unsuccessful_inspection_history: string;
}

interface ReportProps {
  propertyData: WorkOrderData | undefined;
}

export function ReportInspection({ propertyData }: ReportProps): JSX.Element {
  const [allFilled, setAllFilled] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [startTime, setStartTime] = useState<Dayjs>(dayjs());
  const [endTime, setEndTime] = useState<Dayjs>(dayjs());
  const [appointmentTime, setAppointmentTime] = useState<Dayjs>(dayjs());
  const [dateOfContact, setDateOfContact] = useState<Dayjs | null>(null);
  const [scheduleDate, setScheduleDate] = useState<Dayjs | null>(null);
  const [inspectionDate, setInspectionDate] = useState<Dayjs | null>(null);
  const [inputs, setInputs] = useState<ReportInspectionProps>({
    contact_name: '',
    contact_phone: formatPhone(''),
    company_name_and_phone: '',
    contact_date: '',
    inspection_schedule_datetime: '',
    inspection_date: '',
    inspection_start_time: '',
    inspection_end_time: '',
    unsuccessful_inspection_history: '',
  });

  const { osId } = useGeneral();
  const { toggleCompletion } = useReportCompletion();
  const { openSnackbar, setOpenSnackbar, setSnackbarMessage, setErrorMessage } =
    useContext(GlobalContext);

  useEffect(() => {
    if (propertyData) {
      setScheduleDate(
        dayjs(
          propertyData.manual_statistical_report.inspection_information
            ?.inspection_date
        )
      );
      setInspectionDate(
        dayjs(
          propertyData.manual_statistical_report.inspection_information
            ?.inspection_date
        )
      );
      setDateOfContact(
        dayjs(
          propertyData.manual_statistical_report.inspection_information
            ?.contact_date
        )
      );
      setStartTime(
        dayjs(
          `${propertyData.manual_statistical_report.inspection_information?.inspection_date}T${propertyData.manual_statistical_report.inspection_information?.inspection_start_time}`
        )
      );
      setEndTime(
        dayjs(
          `${propertyData.manual_statistical_report.inspection_information?.inspection_date}T${propertyData.manual_statistical_report.inspection_information?.inspection_end_time}`
        )
      );
      setAppointmentTime(
        dayjs(
          propertyData.manual_statistical_report.inspection_information
            ?.inspection_schedule_datetime
        )
      );
      const updateData = {
        contact_name:
          propertyData.manual_statistical_report.inspection_information
            ?.contact_name || '',
        contact_phone:
          propertyData.manual_statistical_report.inspection_information
            ?.contact_phone || '',
        company_name_and_phone:
          propertyData.manual_statistical_report.inspection_information
            ?.company_name_and_phone || '',
        contact_date:
          propertyData.manual_statistical_report.inspection_information
            ?.contact_date || '',
        inspection_schedule_datetime:
          propertyData.manual_statistical_report.inspection_information
            ?.inspection_schedule_datetime || '',
        inspection_date:
          propertyData.manual_statistical_report.inspection_information
            ?.inspection_date || '',
        inspection_start_time:
          propertyData.manual_statistical_report.inspection_information
            ?.inspection_start_time || '',
        inspection_end_time:
          propertyData.manual_statistical_report.inspection_information
            ?.inspection_end_time || '',
        unsuccessful_inspection_history:
          propertyData.manual_statistical_report.inspection_information
            ?.unsuccessful_inspection_history || '',
      };
      setInputs(updateData);
    }
  }, [propertyData]);

  const handleInputChange = (
    field: keyof ReportInspectionProps,
    value: string | number | boolean
  ): void => {
    setInputs((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleStartTime = (newValue: Dayjs | null): void => {
    if (newValue) {
      const endTime = dayjs(newValue).add(1, 'hours');
      setStartTime(newValue);
      setEndTime(endTime);
      setInputs({
        ...inputs,
        inspection_start_time: String(newValue.format('HH:mm')),
        inspection_end_time: String(endTime.format('HH:mm')),
      });
    }
  };

  const handleEndTime = (newValue: Dayjs | null): void => {
    if (newValue) {
      setEndTime(newValue);
      setInputs({
        ...inputs,
        inspection_end_time: String(newValue.format('HH:mm')),
      });
    }
  };

  const handleAppointmentTime = (newValue: Dayjs | null): void => {
    if (newValue) {
      setAppointmentTime(newValue);
      const currentDateTime = dayjs()
        .hour(newValue.hour())
        .minute(newValue.minute())
        .second(0)
        .millisecond(0);
      setInputs({
        ...inputs,
        inspection_schedule_datetime: String(
          currentDateTime.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        ),
      });
    }
  };

  const handleContactDate = (newDate: Dayjs | null): void => {
    const formattedDate = newDate?.format('YYYY-MM-DD');
    setDateOfContact(dayjs(newDate, 'DD-MM-YYYY'));
    if (formattedDate) {
      setInputs({
        ...inputs,
        contact_date: formattedDate?.toString(),
      });
    }
  };

  const handleScheduleDate = (newDate: Dayjs | null): void => {
    const formattedDate = newDate?.format('YYYY-MM-DD');
    setScheduleDate(dayjs(newDate, 'DD-MM-YYYY'));
    if (formattedDate) {
      setInputs({
        ...inputs,
        inspection_schedule_datetime: formattedDate?.toString(),
      });
    }
  };

  const handleInspectionDate = (newDate: Dayjs | null): void => {
    const formattedDate = newDate?.format('YYYY-MM-DD');
    setInspectionDate(dayjs(newDate, 'DD-MM-YYYY'));
    if (formattedDate) {
      setInputs({
        ...inputs,
        inspection_date: formattedDate?.toString(),
      });
    }
  };

  const handleSendJSON = useCallback(async () => {
    try {
      const data: StaticalReportData = {
        inspection_information: inputs,
      };
      const response = await patchStatisticalReport(osId, data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (!data) {
        throw new Error('Algo deu errado, tente novamente');
      }
      toggleCompletion('inspection', true);
    } catch (error) {
      setErrorMessage(true);
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
    }
  }, [
    inputs,
    osId,
    setErrorMessage,
    setOpenSnackbar,
    setSnackbarMessage,
    toggleCompletion,
  ]);

  const checkIfAllFieldsAreFilled = (
    object: ReportInspectionProps
  ): boolean => {
    return Object.values(object).every((value) => value !== '');
  };

  useEffect(() => {
    setAllFilled(checkIfAllFieldsAreFilled(inputs));
    if (
      allFilled &&
      propertyData?.manual_statistical_report.inspection_information
    ) {
      setIsDisabled(false);
      toggleCompletion('inspection', true);
    } else if (allFilled) {
      setIsDisabled(true);
    }
  }, [allFilled, inputs, propertyData, toggleCompletion]);

  return (
    <Grid container rowGap={2}>
      <Grid container columnGap={2}>
        <Tooltip
          arrow
          placement="top"
          title="nome do contato para agendamento da vistoria"
        >
          <Grid item xs={3.5}>
            <TextField
              label="nome do contato para agendamento da vistoria"
              value={inputs.contact_name}
              onChange={(e) =>
                handleInputChange('contact_name', e.target.value)
              }
            />
          </Grid>
        </Tooltip>
        <Grid item xs={3.5}>
          <TextField
            label="telefone do contato"
            value={inputs.contact_phone}
            inputProps={{ maxLenght: 15 }}
            onChange={(e) =>
              handleInputChange('contact_phone', formatPhone(e.target.value))
            }
          />
        </Grid>
        <Tooltip
          arrow
          placement="top"
          title="nome e telefone da pessoa que possa atestar o fato"
        >
          <Grid item xs={3.5}>
            <TextField
              label="nome e telefone da pessoa que possa atestar o fato"
              value={inputs.company_name_and_phone}
              onChange={(e) =>
                handleInputChange('company_name_and_phone', e.target.value)
              }
            />
          </Grid>
        </Tooltip>
      </Grid>
      <Grid container columnGap={2}>
        <Grid item xs={3.5}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="data do contato"
              format="DD-MM-YYYY"
              value={dateOfContact}
              slots={{
                textField:
                  StyledTextField as React.ComponentType<TextFieldProps>,
              }}
              onChange={handleContactDate}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3.5}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="data do agendamento da vistoria"
              format="DD-MM-YYYY"
              value={scheduleDate}
              slots={{
                textField:
                  StyledTextField as React.ComponentType<TextFieldProps>,
              }}
              onChange={handleScheduleDate}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3.5}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopTimePicker
              label="hora do agendamento da vistoria"
              ampm={false}
              value={appointmentTime}
              slots={{
                textField:
                  StyledTextField as React.ComponentType<TextFieldProps>,
              }}
              onChange={handleAppointmentTime}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid container columnGap={2}>
        <Grid item xs={3.5}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="data da vistoria"
              format="DD-MM-YYYY"
              value={inspectionDate}
              slots={{
                textField:
                  StyledTextField as React.ComponentType<TextFieldProps>,
              }}
              onChange={handleInspectionDate}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3.5}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopTimePicker
              label="hora da chegada"
              ampm={false}
              value={startTime}
              slots={{
                textField:
                  StyledTextField as React.ComponentType<TextFieldProps>,
              }}
              onChange={handleStartTime}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3.5}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopTimePicker
              label="hora da saída"
              ampm={false}
              value={endTime}
              slots={{
                textField:
                  StyledTextField as React.ComponentType<TextFieldProps>,
              }}
              onChange={handleEndTime}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="histórico de vistorias infrutíferas, caso houver"
          value={inputs.unsuccessful_inspection_history}
          onChange={(e) =>
            handleInputChange('unsuccessful_inspection_history', e.target.value)
          }
        />
      </Grid>
      <Grid container justifyContent="flex-end" columnGap={2}>
        <Grid item paddingRight={2}>
          <EditButton disabled={isDisabled} onClick={() => handleSendJSON()}>
            {IconSaveMS}
          </EditButton>
        </Grid>
      </Grid>
      {openSnackbar && <Snackbar />}
    </Grid>
  );
}
