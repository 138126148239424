import {
  Box,
  CardMedia,
  Paper,
  Typography,
  TypographyProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const CardContainer = styled(Paper)(() => ({
  borderRadius: '10px',
  margin: '8px 0 16px',
  padding: '16px',
  border: '1.5px solid',
  overflow: 'hidden',
  position: 'relative',
}));

export const TypeFlag = styled(Box)(() => ({
  width: '50px',
  height: '42px',
  float: 'right',
  margin: '-17.5px -17px 0',
  clipPath: 'polygon(100% 0, 0 0, 100% 100%)',
  borderTopRightRadius: '9px',
}));

export const BankFlag = styled(CardMedia)(() => ({
  width: '18px',
  height: '18px',
  float: 'right',
  margin: '-12px -30px 0',
  zIndex: '200',
  position: 'relative',
}));

interface CardLabelProps extends TypographyProps {
  late?: boolean;
}

export const CardLabel = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'late',
})<CardLabelProps>(({ late }) => ({
  fontSize: '14px',
  fontWeight: '600',
  color: '#1A1A1A',
  textAlign: 'start',
  ...(late && {
    color: '#a60e0e',
  }),
}));

export const info = {
  fontWeight: '400',
  paddingLeft: '10px',
};

export const AvmStatusBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  flexDirection: 'column',
  right: 5,
  bottom: 5,
  gap: 4,
  color: theme.palette.primary.dark,
  p: {
    fontSize: 10,
    margin: 0,
    visibility: 'hidden',
  },
  ':hover': {
    p: {
      visibility: 'visible',
      transition: 'visibility 0.3s ease-out',
    },
  },
}));

export const ProtoIcon = styled(CardMedia)(() => ({
  width: '30px',
  height: '30px',
}));

export const NoticesBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: 12,
  bottom: 0,
  width: 'fit-content',
  span: {
    color: theme.palette.primary.main,
    fontSize: 34,
  },
}));

export const NoticesNumber = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: -8,
  right: -8,
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.common.danger,
  borderRadius: 30,
  border: `1px solid ${theme.palette.common.white}`,
  color: theme.palette.common.white,
  fontSize: 12,
}));
