/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Tooltip,
} from '@mui/material';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { patchStatisticalReport } from '../../../../api/workOrders';
import {
  StaticalReportData,
  WorkOrderData,
} from '../../../../api/workOrders/types';
import { IconSaveMS } from '../../../../constants/icons';
import { GlobalContext } from '../../../../context/global';
import { getErrorMessage } from '../../../../helpers';
import useGeneral from '../../../../hooks/useGeneral';
import { useReportCompletion } from '../../../../hooks/useReportCompletion';
import Snackbar from '../../../Snackbar';
import {
  EditButton,
  StyledMenuItem,
  StyledSelect,
  StyledTextField,
} from '../styles';

interface GeneralConditionsProps {
  documents_information_matches_with_inspection: boolean | null;
  unmatched_information_reason: string;
  stability_conditions: boolean | null;
  instability_conditions_reason: string;
  construction_defects: boolean | null;
  construction_defects_reason: string;
  liveability_conditions: boolean | null;
  unlivability_reason: string;
  affected_by_environmental_climatic_or_localization_factors: boolean | null;
  constructed_after_inovation_system_ruled_by_DATEC_with_approval_of_SINAT_within_the_scope_of_PBQP_H:
    | boolean
    | null;
  valuing_factors: string[];
  other_valuing_factors: string;
  depreciative_factors: string[];
  other_depreciative_factors: string;
}

interface GeneralConditionsDataProps {
  propertyData: WorkOrderData | undefined;
}

export function GeneralConditions({
  propertyData,
}: GeneralConditionsDataProps): JSX.Element {
  const [allFilled, setAllFilled] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [inputs, setInputs] = useState<GeneralConditionsProps>({
    documents_information_matches_with_inspection: null,
    unmatched_information_reason: '',
    stability_conditions: null,
    instability_conditions_reason: '',
    construction_defects: null,
    construction_defects_reason: '',
    liveability_conditions: null,
    unlivability_reason: '',
    affected_by_environmental_climatic_or_localization_factors: null,
    constructed_after_inovation_system_ruled_by_DATEC_with_approval_of_SINAT_within_the_scope_of_PBQP_H:
      null,
    valuing_factors: [],
    other_valuing_factors: '',
    depreciative_factors: [],
    other_depreciative_factors: '',
  });

  const valuingFactors = [
    { label: 'vista - mar', value: 'vista - mar' },
    { label: 'vista - parque', value: 'vista - parque' },
    { label: 'vista permanente', value: 'vista permanente' },
    { label: 'não possui', value: 'não possui' },
  ];

  const depreciativeFactors = [
    { label: 'favela', value: 'favela' },
    { label: 'córregos / rios', value: 'córregos/ rios' },
    { label: 'rede de alta tensão', value: 'rede de alta tensão' },
    { label: 'presídio', value: 'presídio' },
    { label: 'feira-livre', value: 'feira-livre' },
    { label: 'não possui', value: 'não possui' },
  ];

  const { osId } = useGeneral();
  const { toggleCompletion } = useReportCompletion();
  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  useEffect(() => {
    if (propertyData?.manual_statistical_report.general_conditions) {
      setInputs(propertyData?.manual_statistical_report.general_conditions);
    }
  }, [propertyData]);

  const handleInputChange = (
    field: keyof GeneralConditionsProps,
    value: string | number | boolean
  ): void => {
    setInputs((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSelectValuingFactors = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const { value, checked } = event.target;

    setInputs((prev) => ({
      ...prev,
      valuing_factors: checked
        ? [...prev.valuing_factors, value]
        : [...prev.valuing_factors.filter((item) => item !== value)],
    }));
  };

  const handleSelectDepreciativeFactors = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const { value, checked } = event.target;

    setInputs((prev) => ({
      ...prev,
      depreciative_factors: checked
        ? [...prev.depreciative_factors, value]
        : [...prev.depreciative_factors.filter((item) => item !== value)],
    }));
  };

  const checkIfAllFieldsAreFilled = (
    object: GeneralConditionsProps
  ): boolean => {
    return Object.entries(object).every(
      ([key, value]) =>
        key === 'unmatched_information_reason' ||
        key === 'stability_conditions' ||
        key === 'instability_conditions_reason' ||
        key === 'construction_defects_reason' ||
        key === 'unlivability_reason' ||
        key === 'other_valuing_factors' ||
        key === 'other_depreciative_factors' ||
        value !== ''
    );
  };

  const handleSendJSON = useCallback(async () => {
    try {
      const data: StaticalReportData = {
        general_conditions: inputs,
      };
      const response = await patchStatisticalReport(osId, data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (!data) {
        throw new Error('Algo deu errado, tente novamente');
      }
      toggleCompletion('generalConditions', true);
    } catch (error) {
      setErrorMessage(true);
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
    }
  }, [
    inputs,
    osId,
    setErrorMessage,
    setOpenSnackbar,
    setSnackbarMessage,
    toggleCompletion,
  ]);

  useEffect(() => {
    setAllFilled(checkIfAllFieldsAreFilled(inputs));

    if (
      String(inputs.documents_information_matches_with_inspection) ===
        'false' &&
      inputs.unmatched_information_reason === ''
    ) {
      setIsDisabled(true);
      return;
    }
    if (
      String(inputs.stability_conditions) === 'false' &&
      inputs.instability_conditions_reason === ''
    ) {
      setIsDisabled(true);
      return;
    }
    if (
      String(inputs.construction_defects) === 'true' &&
      inputs.construction_defects_reason === ''
    ) {
      setIsDisabled(true);
      return;
    }
    if (
      String(inputs.liveability_conditions) === 'false' &&
      inputs.unlivability_reason === ''
    ) {
      setIsDisabled(true);
      return;
    }
    allFilled === true ? setIsDisabled(false) : setIsDisabled(true);
  }, [allFilled, inputs]);

  return (
    <Grid container rowGap={2}>
      <Grid item container columnGap={2}>
        <Tooltip
          arrow
          placement="top"
          title="as informações da documentação correspondem à verificadas na vistoria"
        >
          <Grid item xs={5}>
            <StyledSelect
              select
              label="as informações da documentação correspondem à verificadas na vistoria"
              value={String(
                inputs.documents_information_matches_with_inspection
              )}
              SelectProps={{
                IconComponent: ExpandMoreIcon,
              }}
              onChange={(e) =>
                handleInputChange(
                  'documents_information_matches_with_inspection',
                  e.target.value
                )
              }
            >
              <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
              <StyledMenuItem value="true">sim</StyledMenuItem>
              <StyledMenuItem value="false">não</StyledMenuItem>
            </StyledSelect>
          </Grid>
        </Tooltip>
        {String(inputs.documents_information_matches_with_inspection) ===
          'false' && (
          <Grid item xs={5}>
            <StyledTextField
              label="justifique"
              required
              value={inputs.unmatched_information_reason}
              onChange={(e) =>
                handleInputChange(
                  'unmatched_information_reason',
                  e.target.value
                )
              }
            />
          </Grid>
        )}
      </Grid>
      <Grid item container columnGap={2}>
        <Tooltip
          arrow
          placement="top"
          title="o imóvel aparenta condições de estabilidade e solidez"
        >
          <Grid item xs={5}>
            <StyledSelect
              select
              label="o imóvel aparenta condições de estabilidade e solidez"
              value={String(inputs.stability_conditions)}
              SelectProps={{
                IconComponent: ExpandMoreIcon,
              }}
              onChange={(e) =>
                handleInputChange('stability_conditions', e.target.value)
              }
            >
              <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
              <StyledMenuItem value="true">sim</StyledMenuItem>
              <StyledMenuItem value="false">não</StyledMenuItem>
            </StyledSelect>
          </Grid>
        </Tooltip>
        {String(inputs.stability_conditions) === 'false' && (
          <Grid item xs={5}>
            <StyledTextField
              label="justifique"
              required
              value={String(inputs.instability_conditions_reason)}
              onChange={(e) =>
                handleInputChange(
                  'instability_conditions_reason',
                  e.target.value
                )
              }
            />
          </Grid>
        )}
      </Grid>
      <Grid item container columnGap={2}>
        <Grid item xs={5}>
          <StyledSelect
            select
            label="o imóvel apresenta vícios de construção aparentes"
            value={String(inputs.construction_defects)}
            SelectProps={{
              IconComponent: ExpandMoreIcon,
            }}
            onChange={(e) =>
              handleInputChange('construction_defects', e.target.value)
            }
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="true">sim</StyledMenuItem>
            <StyledMenuItem value="false">não</StyledMenuItem>
          </StyledSelect>
        </Grid>
        {String(inputs.construction_defects) === 'true' && (
          <Grid item xs={5}>
            <StyledTextField
              label="justifique"
              required
              value={String(inputs.construction_defects_reason)}
              onChange={(e) =>
                handleInputChange('construction_defects_reason', e.target.value)
              }
            />
          </Grid>
        )}
      </Grid>
      <Grid item container columnGap={2}>
        <Grid item xs={5}>
          <StyledSelect
            select
            label="o imóvel aparenta condições de habitabilidade?"
            value={String(inputs.liveability_conditions)}
            SelectProps={{
              IconComponent: ExpandMoreIcon,
            }}
            onChange={(e) =>
              handleInputChange('liveability_conditions', e.target.value)
            }
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="true">sim</StyledMenuItem>
            <StyledMenuItem value="false">não</StyledMenuItem>
          </StyledSelect>
        </Grid>
        {String(inputs.liveability_conditions) === 'false' && (
          <Grid item xs={5}>
            <StyledTextField
              label="justifique"
              required
              value={String(inputs.unlivability_reason)}
              onChange={(e) =>
                handleInputChange('unlivability_reason', e.target.value)
              }
            />
          </Grid>
        )}
      </Grid>
      <Grid item container columnGap={2}>
        <Tooltip
          arrow
          placement="top"
          title="o imóvel é afetado significativamente por fatores ambientais, climáticos, localização, etc"
        >
          <Grid item xs={5}>
            <StyledSelect
              select
              label="o imóvel é afetado significativamente por fatores ambientais, climáticos, localização, etc"
              SelectProps={{
                IconComponent: ExpandMoreIcon,
              }}
              value={String(
                inputs.affected_by_environmental_climatic_or_localization_factors
              )}
              onChange={(e) =>
                handleInputChange(
                  'affected_by_environmental_climatic_or_localization_factors',
                  e.target.value
                )
              }
            >
              <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
              <StyledMenuItem value="true">sim</StyledMenuItem>
              <StyledMenuItem value="false">não</StyledMenuItem>
            </StyledSelect>
          </Grid>
        </Tooltip>
        <Tooltip
          arrow
          placement="top"
          title="o imóvel foi construído a partir do sistema inovador regulamentado pelo DATEC com chancela do SINAT no âmbito do PBQP-H"
        >
          <Grid item xs={5}>
            <StyledSelect
              select
              label="o imóvel foi construído a partir do sistema inovador regulamentado pelo DATEC com chancela do SINAT no âmbito do PBQP-H"
              SelectProps={{
                IconComponent: ExpandMoreIcon,
              }}
              value={String(
                inputs.constructed_after_inovation_system_ruled_by_DATEC_with_approval_of_SINAT_within_the_scope_of_PBQP_H
              )}
              onChange={(e) =>
                handleInputChange(
                  'constructed_after_inovation_system_ruled_by_DATEC_with_approval_of_SINAT_within_the_scope_of_PBQP_H',
                  e.target.value
                )
              }
            >
              <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
              <StyledMenuItem value="true">sim</StyledMenuItem>
              <StyledMenuItem value="false">não</StyledMenuItem>
            </StyledSelect>
          </Grid>
        </Tooltip>
      </Grid>
      <Grid item xs={12} margin={2}>
        <FormLabel>fatores valorizantes</FormLabel>
      </Grid>
      <Grid item container rowGap={2} columnGap={2} xs={6}>
        {valuingFactors.map((item) => (
          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value={item.value}
                    checked={inputs.valuing_factors.includes(item.value)}
                    onChange={handleSelectValuingFactors}
                  />
                }
                label={item.label}
              />
            </FormGroup>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={4}>
        <StyledTextField
          placeholder="outros"
          value={inputs.other_valuing_factors}
          onChange={(e) =>
            handleInputChange('other_valuing_factors', e.target.value)
          }
        />
      </Grid>
      <Grid item xs={12} margin={2}>
        <FormLabel>restritivos/depreciação</FormLabel>
      </Grid>
      <Grid item xs={6} container columnGap={2} rowGap={2}>
        {depreciativeFactors.map((item) => (
          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value={item.value}
                    checked={inputs.depreciative_factors.includes(item.value)}
                    onChange={handleSelectDepreciativeFactors}
                  />
                }
                label={item.label}
              />
            </FormGroup>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={4}>
        <StyledTextField
          placeholder="outros"
          value={inputs.other_depreciative_factors}
          onChange={(e) =>
            handleInputChange('other_depreciative_factors', e.target.value)
          }
        />
      </Grid>
      <Grid container justifyContent="flex-end" columnGap={2}>
        <Grid item paddingRight={2}>
          <EditButton
            type="submit"
            disabled={isDisabled}
            onClick={() => handleSendJSON()}
          >
            {IconSaveMS}
          </EditButton>
        </Grid>
      </Grid>
      {openSnackbar && <Snackbar />}
    </Grid>
  );
}
