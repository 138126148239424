/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';

import {
  ClientKind,
  EvaluationType,
  Status,
  convertClientKind,
  convertEvaluationType,
  convertReportFunction,
  convertReportGoal,
} from '../../../../../api/enumerations';
import {
  AvmStatusData,
  WorkOrderData,
} from '../../../../../api/workOrders/types';
import protoFaceWainting from '../../../../../assets/avatars/Avatar-2.png';
import protoFaceFailed from '../../../../../assets/avatars/Avatar-6.png';
import logoBb from '../../../../../assets/images/bankLogo/logo-bb.svg';
import logoCaixa from '../../../../../assets/images/bankLogo/logo-caixa.svg';
import { Constants } from '../../../../../constants/home';
import { IconMailMS } from '../../../../../constants/icons';
import { GlobalContext } from '../../../../../context/global';
import { checkCardColor, formatDate } from '../../../../../helpers';
import {
  AvmStatusBox,
  BankFlag,
  CardContainer,
  CardLabel,
  info,
  NoticesBox,
  NoticesNumber,
  ProtoIcon,
  TypeFlag,
} from './styles';

interface CardProps {
  cardData: WorkOrderData;
}

export function Card({ cardData }: CardProps): JSX.Element {
  const [hasResponsible, setHasResponsible] = useState(false);
  const [limitAlert, setLimitAlert] = useState(false);
  const [limitOver, setLimitOver] = useState(false);
  const [calculationError, setCalculationError] = useState(false);
  const avmStatus = cardData.status === Status.CALCULATION;
  const hasSchedule =
    cardData.evaluation_type === EvaluationType.SIMPFACTORS ||
    cardData.evaluation_type === EvaluationType.SIMPINFERENCES;
  const responsibleStatus = [
    Status.INSPECTION,
    Status.SAMPLE_CREATION,
    Status.CALCULATION,
    Status.REPORT,
    Status.REVISION,
  ];
  const isBank = process.env.REACT_APP_IS_BANK === 'true';

  const { company } = useContext(GlobalContext);

  useEffect(() => {
    if (responsibleStatus.includes(cardData.status)) {
      setHasResponsible(true);
    }
    if (cardData.status === Status.CALCULATION) {
      if (cardData.avm_status) {
        if (
          cardData.avm_status.status === 'not found' ||
          cardData.avm_status.status === 'failed' ||
          cardData.avm_status.status === 'undetermined' ||
          (cardData.avm_status.failure_reason &&
            cardData.avm_status.failure_reason?.length > 0)
        ) {
          setCalculationError(true);
        }
      }
    }
    if (
      company?.show_work_orders_deadline_warning &&
      cardData.limit_finish_at
    ) {
      const dateOver = new Date(cardData.limit_finish_at);
      const d = new Date(cardData.limit_finish_at);
      const deadline = cardData.warning_deadline_in_days || 3;
      const dateAlert = new Date(d.setDate(d.getDate() - deadline));
      const today = new Date();
      today.setHours(23);

      if (today >= dateOver) {
        setLimitOver(true);
        return;
      }
      if (today >= dateAlert) {
        setLimitAlert(true);
      }
    }
  }, []);

  const handleAvmStatus = (avmStatus: AvmStatusData | null): string => {
    switch (avmStatus?.status) {
      case 'failed':
        return 'erro de cálculo';
      default:
        return 'calculando...';
    }
  };

  return (
    <CardContainer
      key={cardData.id}
      sx={{
        borderColor: checkCardColor(
          cardData.client_kind,
          limitOver,
          limitAlert,
          calculationError
        ),
        backgroundColor: limitAlert ? '#F8F7E4' : '#FAFCFF',
      }}
    >
      <TypeFlag
        sx={{
          backgroundColor: checkCardColor(
            cardData.client_kind,
            limitOver,
            limitAlert
          ),
        }}
      />
      {cardData.client_kind === ClientKind.BRASIL_BANK && (
        <BankFlag image={logoBb} title="logo banco do brasil" />
      )}
      {cardData.client_kind === ClientKind.CAIXA && (
        <BankFlag image={logoCaixa} title="logo caixa" />
      )}
      <CardLabel late={limitOver || calculationError}>
        {Constants.os}
        <span style={info}>{cardData.reference_number}</span>
      </CardLabel>
      {hasResponsible && hasSchedule ? (
        <CardLabel late={limitOver || calculationError}>
          {Constants.inspector}
          <span style={info}>
            {cardData.inspection && cardData.inspection.engineer_name}
          </span>
        </CardLabel>
      ) : (
        <CardLabel late={limitOver}>
          {Constants.client}
          <span style={info}>{cardData.client_name}</span>
        </CardLabel>
      )}
      <CardLabel late={limitOver || calculationError}>
        {Constants.clientType}
        <span style={info}>{convertClientKind(cardData.client_kind)}</span>
      </CardLabel>
      <CardLabel late={limitOver || calculationError}>
        {Constants.address}
        <span style={info}>
          {cardData.street}, {cardData.number}
        </span>
      </CardLabel>
      {hasResponsible ? (
        <>
          {hasSchedule && (
            <CardLabel late={limitOver || calculationError}>
              {Constants.schedule}
              <span style={info}>
                {cardData.inspection?.schedule_at &&
                  formatDate(cardData.inspection.schedule_at)}
              </span>
            </CardLabel>
          )}
          <CardLabel late={limitOver || calculationError}>
            {Constants.limitFinish}
            <span style={info}>
              {cardData.limit_finish_at && formatDate(cardData.limit_finish_at)}
            </span>
          </CardLabel>
        </>
      ) : (
        <>
          <CardLabel late={limitOver}>
            {Constants.report}
            <span style={info}>
              {convertEvaluationType(cardData.evaluation_type)}
            </span>
          </CardLabel>
          <CardLabel late={limitOver}>
            {Constants.goal}
            <span style={info}>{convertReportGoal(cardData.report_goal)}</span>
          </CardLabel>
          <CardLabel late={limitOver}>
            {Constants.function}
            <span style={info}>
              {convertReportFunction(cardData.report_function)}
            </span>
          </CardLabel>
        </>
      )}
      {avmStatus && (
        <AvmStatusBox>
          <ProtoIcon
            image={
              cardData.avm_status?.failure_reason
                ? protoFaceFailed
                : protoFaceWainting
            }
          />
          <p>{handleAvmStatus(cardData.avm_status)}</p>
        </AvmStatusBox>
      )}
      {isBank && cardData.notices && !avmStatus && (
        <NoticesBox>
          {IconMailMS}
          <NoticesNumber>{2}</NoticesNumber>
        </NoticesBox>
      )}
    </CardContainer>
  );
}
