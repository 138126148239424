/* eslint-disable react/require-default-props */
/* eslint-disable camelcase */
import {
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { useAppraisalInformation } from '../../../../../../hooks/useAppraisalInformation';
import { StyledTableCellBody, StyledTextField } from '../../../styles';

interface SpotsProps {
  covered: number;
  not_covered: number;
  total_covered_and_not_covered: number;
  free: number;
  locked: number;
  total_free_and_locked: number;
}

interface ParkingInformationProps {
  linked_spots: SpotsProps;
  independent_spots: SpotsProps;
  total_public_spots: number;
  total_private_spots: number;
}

interface TableVacanciesProps {
  parking?: ParkingInformationProps | undefined;
}

export function TableVacancies({ parking }: TableVacanciesProps): JSX.Element {
  const [allFilled, setAllFilled] = useState(false);
  const [
    totalCoveredAndNotCoveredLinkedSpots,
    setTotalCoveredAndNotCoveredLinkedSpots,
  ] = useState(0);
  const [totalFreeAndLockedLinkedSpots, setTotalFreeAndLockedLinkedSpots] =
    useState(0);
  const [
    totalCoveredAndNotCoveredIndependentSpot,
    setTotalCoveredAndNotCoveredIndependentSpot,
  ] = useState(0);
  const [
    totalFreeAndLockedIndependentSpot,
    setTotalFreeAndLockedIndependentSpot,
  ] = useState(0);

  const { setParkingInformation, parking_information } =
    useAppraisalInformation();
  const [linkedSpots, setLinkedSpots] = useState<SpotsProps>({
    covered: parking_information.linked_spots.covered,
    not_covered: parking_information.linked_spots.not_covered,
    total_covered_and_not_covered:
      parking_information.linked_spots.total_covered_and_not_covered ||
      totalCoveredAndNotCoveredLinkedSpots,
    free: parking_information.linked_spots.free,
    locked: parking_information.linked_spots.locked,
    total_free_and_locked:
      parking_information.linked_spots.total_free_and_locked ||
      totalFreeAndLockedLinkedSpots,
  });
  const [independentSpots, setIndependentSpots] = useState<SpotsProps>({
    covered: parking_information.independent_spots.covered,
    not_covered: parking_information.independent_spots.not_covered,
    total_covered_and_not_covered:
      parking_information.independent_spots.total_covered_and_not_covered ||
      totalCoveredAndNotCoveredIndependentSpot,
    free: parking_information.independent_spots.free,
    locked: parking_information.independent_spots.locked,
    total_free_and_locked:
      parking_information.independent_spots.total_free_and_locked ||
      totalFreeAndLockedIndependentSpot,
  });

  const [inputs, setInputs] = useState<ParkingInformationProps>({
    linked_spots: linkedSpots,
    independent_spots: independentSpots,
    total_public_spots: parking_information.total_public_spots,
    total_private_spots: parking_information.total_private_spots,
  });

  const handleInputChange = (
    field: keyof ParkingInformationProps,
    value: string | number | boolean
  ): void => {
    setInputs((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const checkIfAllFieldsAreFilledInParkingInformation = (
    object: ParkingInformationProps
  ): boolean => {
    return Object.entries(object).every(
      ([key, value]) =>
        key === 'linked_spots' || key === 'independent_spots' || value !== ''
    );
  };

  const checkIfAllFieldsAreFilledInSpots = (object: SpotsProps): boolean => {
    return Object.values(object).every((value) => value !== '');
  };

  const handleLinkedSpotInputChange = (
    field: keyof SpotsProps,
    value: string | number
  ): void => {
    setLinkedSpots((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleIndependentSpotInputChange = (
    field: keyof SpotsProps,
    value: string | number
  ): void => {
    setIndependentSpots((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  useEffect(() => {
    if (parking !== undefined) {
      setLinkedSpots(parking.linked_spots);
      setIndependentSpots(parking.independent_spots);
      setInputs((prev) => ({
        ...prev,
        linked_spots: parking.linked_spots,
        independent_spots: parking.independent_spots,
        total_private_spots: parking.total_private_spots,
        total_public_spots: parking.total_public_spots,
      }));
    }
  }, [parking]);

  useEffect(() => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      linked_spots: linkedSpots,
      independent_spots: independentSpots,
    }));
  }, [linkedSpots, independentSpots]);

  useEffect(() => {
    setAllFilled(
      checkIfAllFieldsAreFilledInParkingInformation(inputs) &&
        checkIfAllFieldsAreFilledInSpots(linkedSpots) &&
        checkIfAllFieldsAreFilledInSpots(independentSpots)
    );

    if (allFilled) {
      setParkingInformation(inputs);
    }
  }, [allFilled, independentSpots, inputs, linkedSpots, setParkingInformation]);

  useEffect(() => {
    if (
      linkedSpots.covered ||
      linkedSpots.not_covered ||
      linkedSpots.free ||
      linkedSpots.locked
    ) {
      setTotalCoveredAndNotCoveredLinkedSpots(
        linkedSpots.covered + linkedSpots.not_covered
      );
      setTotalFreeAndLockedLinkedSpots(linkedSpots.free + linkedSpots.locked);
    }
    if (
      independentSpots.covered ||
      independentSpots.not_covered ||
      independentSpots.free ||
      independentSpots.locked
    ) {
      setTotalCoveredAndNotCoveredIndependentSpot(
        independentSpots.covered + independentSpots.not_covered
      );
      setTotalFreeAndLockedIndependentSpot(
        independentSpots.free + independentSpots.locked
      );
    }
  }, [independentSpots, linkedSpots]);

  return (
    <>
      <TableContainer>
        <Table>
          <TableRow sx={{ border: '1px solid #ccc' }}>
            <TableCell rowSpan={2} align="center">
              estacionamento
            </TableCell>
            <TableCell
              colSpan={6}
              align="center"
              sx={{ backgroundColor: '#F4EEF6', border: '1px solid #ccc' }}
            >
              quantidate
            </TableCell>
          </TableRow>
          <TableRow
            sx={{ backgroundColor: '#E9E1ED', border: '1px solid #ccc' }}
          >
            <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
              cobertas
            </TableCell>
            <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
              descobertas
            </TableCell>
            <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
              total
            </TableCell>
            <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
              livres
            </TableCell>
            <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
              presa
            </TableCell>
            <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
              total
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
              vaga(s) vinculada(s)
            </TableCell>
            <StyledTableCellBody>
              <TextField
                type="number"
                multiline
                value={linkedSpots.covered}
                onChange={(e) =>
                  handleLinkedSpotInputChange('covered', Number(e.target.value))
                }
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <TextField
                type="number"
                multiline
                value={linkedSpots.not_covered}
                onChange={(e) =>
                  handleLinkedSpotInputChange(
                    'not_covered',
                    Number(e.target.value)
                  )
                }
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <TextField
                type="number"
                multiline
                value={totalCoveredAndNotCoveredLinkedSpots}
                onChange={() =>
                  setTotalCoveredAndNotCoveredLinkedSpots(
                    Number(linkedSpots.covered + linkedSpots.not_covered)
                  )
                }
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <TextField
                type="number"
                multiline
                value={linkedSpots.free}
                onChange={(e) =>
                  handleLinkedSpotInputChange('free', Number(e.target.value))
                }
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <TextField
                type="number"
                multiline
                value={linkedSpots.locked}
                onChange={(e) =>
                  handleLinkedSpotInputChange('locked', Number(e.target.value))
                }
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <TextField multiline value={totalFreeAndLockedLinkedSpots} />
            </StyledTableCellBody>
          </TableRow>
          <TableRow>
            <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
              vaga(s) autônoma(s)
            </TableCell>
            <StyledTableCellBody>
              <TextField
                type="number"
                multiline
                value={independentSpots.covered}
                onChange={(e) =>
                  handleIndependentSpotInputChange(
                    'covered',
                    Number(e.target.value)
                  )
                }
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <TextField
                type="number"
                multiline
                value={independentSpots.not_covered}
                onChange={(e) =>
                  handleIndependentSpotInputChange(
                    'not_covered',
                    Number(e.target.value)
                  )
                }
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <TextField
                multiline
                value={totalCoveredAndNotCoveredIndependentSpot}
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <TextField
                multiline
                value={independentSpots.free}
                onChange={(e) =>
                  handleIndependentSpotInputChange(
                    'free',
                    Number(e.target.value)
                  )
                }
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <TextField
                multiline
                value={independentSpots.locked}
                onChange={(e) =>
                  handleIndependentSpotInputChange(
                    'locked',
                    Number(e.target.value)
                  )
                }
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <TextField multiline value={totalFreeAndLockedIndependentSpot} />
            </StyledTableCellBody>
          </TableRow>
        </Table>
      </TableContainer>
      <Grid container columnGap={2}>
        <Grid item xs={5.5}>
          <StyledTextField
            type="number"
            label="total vagas de uso comum"
            value={String(inputs.total_public_spots)}
            onChange={(e) =>
              handleInputChange('total_public_spots', Number(e.target.value))
            }
          />
        </Grid>
        <Grid item xs={5.5}>
          <StyledTextField
            type="number"
            label="quantidade total de vagas privativas"
            value={String(inputs.total_private_spots)}
            onChange={(e) =>
              handleInputChange('total_private_spots', Number(e.target.value))
            }
          />
        </Grid>
      </Grid>
    </>
  );
}
