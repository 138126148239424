/* eslint-disable camelcase */
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { useAppraisalInformation } from '../../../../../../hooks/useAppraisalInformation';
import { StyledTableCellBody, StyledTableRowBody } from '../../../styles';

export interface RegisteredProps {
  private: number;
  public: number;
  total: number;
}

export interface AreaInformationProps {
  registered: RegisteredProps;
  not_registered: number;
  total_private_area: number;
  total_area: number;
}

interface TableAreaProps {
  areaInformation: AreaInformationProps | undefined;
}

export function TableArea({ areaInformation }: TableAreaProps): JSX.Element {
  const [allFilled, setAllFilled] = useState(false);

  const { setAreaInformation, area_information } = useAppraisalInformation();
  const [registeredInput, setRegisteredInput] = useState<RegisteredProps>({
    private: area_information.registered.private,
    public: area_information.registered.public,
    total: area_information.registered.total,
  });
  const [inputs, setInputs] = useState<AreaInformationProps>({
    registered: registeredInput,
    not_registered: area_information.not_registered,
    total_private_area: area_information.total_private_area,
    total_area: area_information.total_area,
  });

  const handleRegisteredInputChange = (
    field: keyof RegisteredProps,
    value: number
  ): void => {
    setRegisteredInput((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleInputChange = (
    field: keyof AreaInformationProps,
    value: number
  ): void => {
    setInputs((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const checkIfAllFieldsAreFilledInAreaInformation = (
    object: AreaInformationProps
  ): boolean => {
    return Object.entries(object).every(
      ([key, value]) => key === 'registered' || value !== 0
    );
  };

  const checkIfAllFieldsAreFilledInRegistered = (
    object: RegisteredProps
  ): boolean => {
    return Object.values(object).every((value) => value !== 0);
  };

  useEffect(() => {
    if (areaInformation !== undefined) {
      setRegisteredInput(areaInformation.registered);
      setInputs(areaInformation);
    }
  }, [areaInformation]);

  useEffect(() => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      registered: registeredInput,
    }));
  }, [registeredInput]);

  useEffect(() => {
    setAllFilled(
      checkIfAllFieldsAreFilledInAreaInformation(inputs) &&
        checkIfAllFieldsAreFilledInRegistered(registeredInput)
    );
    if (allFilled) {
      setAreaInformation(inputs);
    }
  }, [
    allFilled,
    area_information,
    inputs,
    registeredInput,
    setAreaInformation,
  ]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: '#E9E1ED',
              border: '1px solid #ccc',
            }}
          >
            <TableCell
              rowSpan={3}
              align="center"
              sx={{ backgroundColor: 'white', border: '1px solid #ccc' }}
            >
              área(m²)
            </TableCell>
            <TableCell
              colSpan={3}
              align="center"
              sx={{ backgroundColor: '#F4EEF6', border: '1px solid #ccc' }}
            >
              área averbada
            </TableCell>
            <TableCell
              rowSpan={2}
              align="center"
              sx={{ border: '1px solid #ccc' }}
            >
              área não averbada
            </TableCell>
            <TableCell
              rowSpan={2}
              align="center"
              sx={{ border: '1px solid #ccc' }}
            >
              total área privativa (averbada + não averbada)
            </TableCell>
            <TableCell
              rowSpan={2}
              align="center"
              sx={{ border: '1px solid #ccc' }}
            >
              área total do imóvel (averbadas + não averbadas)
            </TableCell>
          </TableRow>
          <TableRow
            sx={{
              backgroundColor: '#E9E1ED',
              border: '1px solid #ccc',
            }}
          >
            <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
              área privativa
            </TableCell>
            <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
              área comum (m²)
            </TableCell>
            <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
              total área averbada
            </TableCell>
          </TableRow>
          <StyledTableRowBody>
            <StyledTableCellBody>
              <TextField
                multiline
                value={String(registeredInput.private)}
                onChange={(e) =>
                  handleRegisteredInputChange('private', Number(e.target.value))
                }
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <TextField
                multiline
                value={String(registeredInput.public)}
                onChange={(e) =>
                  handleRegisteredInputChange('public', Number(e.target.value))
                }
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <TextField
                multiline
                value={String(registeredInput.total)}
                onChange={(e) =>
                  handleRegisteredInputChange('total', Number(e.target.value))
                }
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <TextField
                multiline
                value={String(inputs.not_registered)}
                onChange={(e) =>
                  handleInputChange('not_registered', Number(e.target.value))
                }
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <TextField
                multiline
                value={String(inputs.total_private_area)}
                onChange={(e) =>
                  handleInputChange(
                    'total_private_area',
                    Number(e.target.value)
                  )
                }
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <TextField
                multiline
                value={String(inputs.total_area)}
                onChange={(e) =>
                  handleInputChange('total_area', Number(e.target.value))
                }
              />
            </StyledTableCellBody>
          </StyledTableRowBody>
        </TableHead>
      </Table>
    </TableContainer>
  );
}
