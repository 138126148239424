import { Grid } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { patchStatisticalReport } from '../../../../api/workOrders';
import {
  StaticalReportData,
  WorkOrderData,
} from '../../../../api/workOrders/types';
import { IconSaveMS } from '../../../../constants/icons';
import { GlobalContext } from '../../../../context/global';
import { getErrorMessage } from '../../../../helpers';
import useGeneral from '../../../../hooks/useGeneral';
import { useReportCompletion } from '../../../../hooks/useReportCompletion';
import { CustomTextField } from '../../../CustomInput';
import Snackbar from '../../../Snackbar';
import { EditButton } from '../styles';

interface FinalRemarkstProps {
  propertyData: WorkOrderData | undefined;
}

export function FinalRemarks({
  propertyData,
}: FinalRemarkstProps): JSX.Element {
  const [finalRemarks, setFinalRemarks] = useState(
    propertyData?.manual_statistical_report.observations || ''
  );

  const { osId } = useGeneral();
  const { toggleCompletion } = useReportCompletion();
  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  useEffect(() => {
    if (propertyData) {
      setFinalRemarks(
        propertyData.manual_statistical_report.observations || ''
      );
      toggleCompletion('finalRemarks', true);
    }
  }, [propertyData, toggleCompletion]);

  const handleSendJSON = useCallback(async () => {
    try {
      const data: StaticalReportData = {
        observations: finalRemarks,
      };
      const response = await patchStatisticalReport(osId, data);
      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (!data) {
        throw new Error('Algo deu errado, tente novamente');
      }
      toggleCompletion('finalRemarks', true);
    } catch (error) {
      setErrorMessage(true);
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
    }
  }, [
    finalRemarks,
    osId,
    setErrorMessage,
    setOpenSnackbar,
    setSnackbarMessage,
    toggleCompletion,
  ]);

  return (
    <Grid container rowGap={2}>
      <Grid item xs={12}>
        <CustomTextField
          id=""
          label=""
          value={finalRemarks}
          setValue={setFinalRemarks}
        />
      </Grid>
      <Grid container justifyContent="flex-end" columnGap={2}>
        <Grid item paddingRight={2}>
          <EditButton
            disabled={finalRemarks === ''}
            onClick={() => handleSendJSON()}
          >
            {IconSaveMS}
          </EditButton>
        </Grid>
      </Grid>
      {openSnackbar && <Snackbar />}
    </Grid>
  );
}
