/* eslint-disable max-len */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/function-component-definition */
import React, { createContext, useContext, useState } from 'react';

import { RegisteredProps } from '../components/Sections/StatisticalReport/InputsBySections/appraisal-information/Tables/TableArea';
import { InternalDivisionDataProps } from '../components/Sections/StatisticalReport/InputsBySections/appraisal-information/Tables/TableInternalDivision';

export interface AreaInformation {
  registered: RegisteredProps;
  not_registered: number;
  total_private_area: number;
  total_area: number;
}

export interface ParkingSpot {
  covered: number;
  not_covered: number;
  total_covered_and_not_covered: number;
  free: number;
  locked: number;
  total_free_and_locked: number;
}

export interface ParkingInformation {
  linked_spots: ParkingSpot;
  independent_spots: ParkingSpot;
  total_public_spots: number;
  total_private_spots: number;
}

export interface InternalDivision {
  room_type: string;
  quantity: number;
}

interface ContextData {
  area_information: AreaInformation;
  parking_information: ParkingInformation;
  internal_division: InternalDivisionDataProps[];
}

interface ContextType extends ContextData {
  setAreaInformation: React.Dispatch<React.SetStateAction<AreaInformation>>;
  setParkingInformation: React.Dispatch<
    React.SetStateAction<ParkingInformation>
  >;
  setInternalDivision: React.Dispatch<React.SetStateAction<InternalDivision[]>>;
}

const initialData: ContextData = {
  area_information: {
    registered: {
      private: 0,
      public: 0,
      total: 0,
    },
    not_registered: 0,
    total_private_area: 0,
    total_area: 0,
  },
  parking_information: {
    linked_spots: {
      covered: 0,
      not_covered: 0,
      total_covered_and_not_covered: 0,
      free: 0,
      locked: 0,
      total_free_and_locked: 0,
    },
    independent_spots: {
      covered: 0,
      not_covered: 0,
      total_covered_and_not_covered: 0,
      free: 0,
      locked: 0,
      total_free_and_locked: 0,
    },
    total_public_spots: 0,
    total_private_spots: 0,
  },
  internal_division: [
    {
      room_type: '',
      quantity: 0,
    },
  ],
};

const AppraisalInformationContext = createContext<ContextType | undefined>(
  undefined
);

export const AppraisalInformationProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [areaInformation, setAreaInformation] = useState(
    initialData.area_information
  );
  const [parkingInformation, setParkingInformation] = useState(
    initialData.parking_information
  );
  const [internalDivision, setInternalDivision] = useState(
    initialData.internal_division
  );

  return (
    <AppraisalInformationContext.Provider
      value={{
        area_information: areaInformation,
        parking_information: parkingInformation,
        internal_division: internalDivision,
        setAreaInformation,
        setParkingInformation,
        setInternalDivision,
      }}
    >
      {children}
    </AppraisalInformationContext.Provider>
  );
};

export const useAppraisalInformation = (): ContextType => {
  const context = useContext(AppraisalInformationContext);
  if (!context) {
    throw new Error('useDataContext must be used within a DataProvider');
  }
  return context;
};
