/* eslint-disable max-lines */
export enum ArchivedReason {
  FINISHED = 1,
  CANCELED,
}

export const convertArchivedReason = (
  archivedReason: ArchivedReason | undefined | null
): string => {
  let word = '';
  switch (archivedReason) {
    case ArchivedReason.FINISHED:
      word = 'finalizado';
      break;
    case ArchivedReason.CANCELED:
      word = 'cancelado';
      break;
    default:
      word = '';
  }
  return word;
};

export enum ClientKind {
  SELECT,
  CORPORATE = 1,
  LANDING = 3,
  BRASIL_BANK = 2,
  CAIXA = 4,
  ITAU = 8,
  BRADESCO,
  SANTANDER,
  NORDESTE,
  BRB,
  BANESTES,
  AMAZONIA,
  BANPARA,
  BANRISUL,
  POUPEX,
  BANCO_INTER,
  C6_BANK,
  SICOOB,
  BNB,
  OTHER_BANK = 5,
}

export const convertClientKind = (clientKind: ClientKind): string => {
  let word = '';
  switch (clientKind) {
    case ClientKind.SELECT:
      word = 'selecione uma opção';
      break;
    case ClientKind.CORPORATE:
      word = 'empresarial';
      break;
    case ClientKind.LANDING:
      word = 'particular';
      break;
    case ClientKind.BRASIL_BANK:
      word = 'Banco do Brasil';
      break;
    case ClientKind.CAIXA:
      word = 'Caixa';
      break;
    case ClientKind.ITAU:
      word = 'Itaú';
      break;
    case ClientKind.BRADESCO:
      word = 'Bradesco';
      break;
    case ClientKind.SANTANDER:
      word = 'Santander';
      break;
    case ClientKind.NORDESTE:
      word = 'Banco do Nordeste';
      break;
    case ClientKind.BRB:
      word = 'Banco BRB';
      break;
    case ClientKind.BANESTES:
      word = 'Banestes';
      break;
    case ClientKind.AMAZONIA:
      word = 'Banco da Amazônia';
      break;
    case ClientKind.BANPARA:
      word = 'Banpará';
      break;
    case ClientKind.BANRISUL:
      word = 'Banrisul';
      break;
    case ClientKind.POUPEX:
      word = 'Poupex';
      break;
    case ClientKind.BANCO_INTER:
      word = 'Banco Inter';
      break;
    case ClientKind.C6_BANK:
      word = 'C6 Bank';
      break;
    case ClientKind.SICOOB:
      word = 'Sicoob';
      break;
    case ClientKind.BNB:
      word = 'BNB';
      break;
    case ClientKind.OTHER_BANK:
      word = 'outros bancos';
      break;
    default:
      word = '';
  }
  return word;
};

export enum Concept {
  SELECT,
  URBAN,
  RURAL,
}

export const convertConcept = (enumType: Concept): string => {
  let word: string;
  switch (enumType) {
    case Concept.SELECT:
      word = 'selecione uma opção';
      break;
    case Concept.URBAN:
      word = 'urbano';
      break;
    case Concept.RURAL:
      word = 'rural';
      break;
    default:
      word = '';
  }
  return word;
};

export enum ConservationState {
  SELECT,
  NEW,
  NEWREGULAR,
  REGULAR,
  REGULARREPAIR,
  EASYREPAIR,
  REPAIREASYBIG,
  BIGREPAIR,
  REPAIRBIGNOVALUE,
  NOVALUE,
}

export const convertConservationState = (
  enumType: ConservationState
): string => {
  let word: string;
  switch (enumType) {
    case ConservationState.SELECT:
      word = 'selecione uma opção';
      break;
    case ConservationState.NEW:
      word = 'novo';
      break;
    case ConservationState.NEWREGULAR:
      word = 'entre novo e regular';
      break;
    case ConservationState.REGULAR:
      word = 'regular';
      break;
    case ConservationState.REGULARREPAIR:
      word = 'regular à reparos simples';
      break;
    case ConservationState.EASYREPAIR:
      word = 'reparos simples';
      break;
    case ConservationState.REPAIREASYBIG:
      word = 'reparos simples à importantes';
      break;
    case ConservationState.BIGREPAIR:
      word = 'reparos importantes';
      break;
    case ConservationState.REPAIRBIGNOVALUE:
      word = 'reparos importantes à edificação sem valor';
      break;
    case ConservationState.NOVALUE:
      word = 'sem valor';
      break;
    default:
      word = '';
  }
  return word;
};

export enum EvaluationType {
  SELECT,
  AUTOFACTORS,
  AUTOINFERENCES,
  SIMPFACTORS,
  SIMPINFERENCES,
}

export const convertEvaluationType = (
  evaluationType: EvaluationType
): string => {
  let word: string;
  switch (evaluationType) {
    case EvaluationType.SELECT:
      word = 'selecione uma opção';
      break;
    case EvaluationType.AUTOFACTORS:
      word = 'Fatores - Sem Vistoria';
      break;
    case EvaluationType.AUTOINFERENCES:
      word = 'Inferência - Sem Vistoria';
      break;
    case EvaluationType.SIMPFACTORS:
      word = 'Fatores - Com Vistoria';
      break;
    case EvaluationType.SIMPINFERENCES:
      word = 'Inferência - Com Vistoria';
      break;
    default:
      word = '';
  }
  return word;
};

export enum EvaluationTypeBank {
  SELECT,
  SIMPINFERENCES = 4,
}

export const convertEvaluationTypeBank = (
  evaluationType: EvaluationTypeBank
): string => {
  let word: string;
  switch (evaluationType) {
    case EvaluationTypeBank.SELECT:
      word = 'selecione uma opção';
      break;
    case EvaluationTypeBank.SIMPINFERENCES:
      word = 'Inferência - Com Vistoria';
      break;
    default:
      word = '';
  }
  return word;
};

export enum FirstAccessStages {
  EMAIL = 1,
  AUTHENTICATION,
  REGISTER,
  IDENTITY,
  SUCCESS,
}

export const convertFirstAccess = (enumType: FirstAccessStages): string => {
  let word: string;
  switch (enumType) {
    case FirstAccessStages.EMAIL:
      word = 'Acesso convidado';
      break;
    case FirstAccessStages.AUTHENTICATION:
      word = 'Autenticação';
      break;
    case FirstAccessStages.REGISTER:
      word = 'Cadastro';
      break;
    case FirstAccessStages.IDENTITY:
      word = 'Reconhecimento facial';
      break;
    case FirstAccessStages.SUCCESS:
      word = 'Cadastro concluído!';
      break;
    default:
      word = '';
  }
  return word;
};

export enum FormPropertyType {
  SELECT,
  APARTMENT,
  HOUSE,
  LOT = 5,
}

export const convertFormPropertyType = (enumType: FormPropertyType): string => {
  let word: string;
  switch (enumType) {
    case FormPropertyType.SELECT:
      word = 'selecione uma opção';
      break;
    case FormPropertyType.APARTMENT:
      word = 'apartamento';
      break;
    case FormPropertyType.HOUSE:
      word = 'casa';
      break;
    case FormPropertyType.LOT:
      word = 'terreno(lote)';
      break;
    default:
      word = '';
  }
  return word;
};

export enum ProfileUsers {
  SELECT,
  ARCHITECT = 1,
  ENGINEER,
  ADMIN,
}

export const convertProfileUsers = (profileUsers: ProfileUsers): string => {
  let word: string;
  switch (profileUsers) {
    case ProfileUsers.SELECT:
      word = 'selecione uma função';
      break;
    case ProfileUsers.ARCHITECT:
      word = 'arquiteto';
      break;
    case ProfileUsers.ENGINEER:
      word = 'engenheiro';
      break;
    case ProfileUsers.ADMIN:
      word = 'admin';
      break;
    default:
      word = '';
  }
  return word;
};

export enum PropertyType {
  SELECT,
  APARTMENT,
  HOUSE,
}

export const convertPropertyType = (enumType: PropertyType): string => {
  let word: string;
  switch (enumType) {
    case PropertyType.SELECT:
      word = 'selecione uma opção';
      break;
    case PropertyType.APARTMENT:
      word = 'apartamento';
      break;
    case PropertyType.HOUSE:
      word = 'casa';
      break;
    default:
      word = '';
  }
  return word;
};

export enum ReportCondominiumPropertUse {
  SELECT,
  COMERCIAL,
  RESIDENCIAL,
  MIXED,
}

export const convertReportCondominiumPropertyUse = (
  enumType: ReportCondominiumPropertUse
): string => {
  let word: string;
  switch (enumType) {
    case ReportCondominiumPropertUse.SELECT:
      word = 'selecione uma opção';
      break;
    case ReportCondominiumPropertUse.COMERCIAL:
      word = 'comercial';
      break;
    case ReportCondominiumPropertUse.RESIDENCIAL:
      word = 'residencial';
      break;
    case ReportCondominiumPropertUse.MIXED:
      word = 'misto';
      break;
    default:
      word = '';
  }
  return word;
};

export enum ReportPropertUse {
  SELECT,
  RESIDENCIAL_UNIFAMILIAR,
  RESIDENCIAL_MULTIFAMILIAR,
  COMERCIAL,
  INDUSTRIAL,
  INSTITUCIONAL,
  OUTRO,
}

export const convertReportPropertyUse = (
  enumType: ReportPropertUse
): string => {
  let word: string;
  switch (enumType) {
    case ReportPropertUse.SELECT:
      word = 'selecione uma opção';
      break;
    case ReportPropertUse.RESIDENCIAL_UNIFAMILIAR:
      word = 'residencial unifamiliar';
      break;
    case ReportPropertUse.RESIDENCIAL_MULTIFAMILIAR:
      word = 'residencial multifamiliar';
      break;
    case ReportPropertUse.COMERCIAL:
      word = 'comercial';
      break;
    case ReportPropertUse.INDUSTRIAL:
      word = 'industrial';
      break;
    case ReportPropertUse.INSTITUCIONAL:
      word = 'institucional';
      break;
    case ReportPropertUse.OUTRO:
      word = 'outro';
      break;
    default:
      word = '';
  }
  return word;
};

export enum PropertyUse {
  SELECT,
  RESIDENCIAL,
  COMERCIAL,
  INDUSTRIAL,
  INSTITUTIONAL,
  MIXED,
}

export const convertPropertyUse = (enumType: PropertyUse): string => {
  let word: string;
  switch (enumType) {
    case PropertyUse.SELECT:
      word = 'selecione uma opção';
      break;
    case PropertyUse.RESIDENCIAL:
      word = 'residencial';
      break;
    case PropertyUse.COMERCIAL:
      word = 'comercial';
      break;
    case PropertyUse.INDUSTRIAL:
      word = 'industrial';
      break;
    case PropertyUse.INSTITUTIONAL:
      word = 'institucional';
      break;
    case PropertyUse.MIXED:
      word = 'misto';
      break;
    default:
      word = '';
  }
  return word;
};

export enum QuestionType {
  SELECT,
  SHORT_ANSWER,
  SINGLE_SELECTION,
  MULTIPLE_CHOICE,
  LIST,
  DATE,
  NUMERIC,
}

export const convertQuestionType = (enumType: QuestionType): string => {
  let word: string;
  switch (enumType) {
    case QuestionType.SELECT:
      word = 'selecione uma opção';
      break;
    case QuestionType.SHORT_ANSWER:
      word = 'resposta curta';
      break;
    case QuestionType.SINGLE_SELECTION:
      word = 'seleção única';
      break;
    case QuestionType.MULTIPLE_CHOICE:
      word = 'múltipla escolha';
      break;
    case QuestionType.LIST:
      word = 'lista suspensa';
      break;
    case QuestionType.DATE:
      word = 'data';
      break;
    case QuestionType.NUMERIC:
      word = 'resposta numérica';
      break;
    default:
      word = '';
  }
  return word;
};

export enum RegistrationUf {
  SELECT,
  AC,
  AL,
  AP,
  AM,
  BA,
  CE,
  DF,
  ES,
  GO,
  MA,
  MT,
  MS,
  MG,
  PA,
  PB,
  PR,
  PE,
  PI,
  RJ,
  RN,
  RS,
  RO,
  RR,
  SC,
  SP,
  SE,
  TO,
}

export const convertRegistrationUf = (enumType: RegistrationUf): string => {
  let word: string;
  switch (enumType) {
    case RegistrationUf.SELECT:
      word = 'selecione uma opção';
      break;
    case RegistrationUf.AC:
      word = 'AC';
      break;
    case RegistrationUf.AL:
      word = 'AL';
      break;
    case RegistrationUf.AP:
      word = 'AP';
      break;
    case RegistrationUf.AM:
      word = 'AM';
      break;
    case RegistrationUf.BA:
      word = 'BA';
      break;
    case RegistrationUf.CE:
      word = 'CE';
      break;
    case RegistrationUf.DF:
      word = 'DF';
      break;
    case RegistrationUf.ES:
      word = 'ES';
      break;
    case RegistrationUf.GO:
      word = 'GO';
      break;
    case RegistrationUf.MA:
      word = 'MA';
      break;
    case RegistrationUf.MT:
      word = 'MT';
      break;
    case RegistrationUf.MS:
      word = 'MS';
      break;
    case RegistrationUf.MG:
      word = 'MG';
      break;
    case RegistrationUf.PA:
      word = 'PA';
      break;
    case RegistrationUf.PB:
      word = 'PB';
      break;
    case RegistrationUf.PR:
      word = 'PR';
      break;
    case RegistrationUf.PE:
      word = 'PE';
      break;
    case RegistrationUf.PI:
      word = 'PI';
      break;
    case RegistrationUf.RJ:
      word = 'RJ';
      break;
    case RegistrationUf.RN:
      word = 'RN';
      break;
    case RegistrationUf.RS:
      word = 'RS';
      break;
    case RegistrationUf.RO:
      word = 'RO';
      break;
    case RegistrationUf.RR:
      word = 'RR';
      break;
    case RegistrationUf.SC:
      word = 'SC';
      break;
    case RegistrationUf.SP:
      word = 'SP';
      break;
    case RegistrationUf.SE:
      word = 'SE';
      break;
    case RegistrationUf.TO:
      word = 'TO';
      break;
    default:
      word = '';
  }
  return word;
};

export enum ReportGoal {
  SELECT,
  MARKET_BUY,
  MARKET_TENANCY,
  REEDITION,
  LIQUIDATION,
  OTHERS,
}

export const convertReportGoal = (reportGoal: ReportGoal): string => {
  let word = '';
  switch (reportGoal) {
    case ReportGoal.SELECT:
      word = 'selecione uma opção';
      break;
    case ReportGoal.MARKET_BUY:
      word = 'valor de mercado de compra e venda';
      break;
    case ReportGoal.MARKET_TENANCY:
      word = 'valor de mercado de locação';
      break;
    case ReportGoal.REEDITION:
      word = 'custo de reedição';
      break;
    case ReportGoal.LIQUIDATION:
      word = 'valor de liquidação forçada';
      break;
    case ReportGoal.OTHERS:
      word = 'outros';
      break;
    default:
      word = '';
  }
  return word;
};

export enum ReportFunction {
  SELECT,
  TENANCY,
  LEASE,
  LENDING,
  ACQUISITION,
  DONATION,
  ALIENATION,
  GIVING_PAYMENT,
  EXCHANGE,
  GUARANTEE,
  ACCOUNTING,
  INSURANCE,
  AUCTION,
  ADJUDICATION,
  REPARATION,
  TAXATION,
  OTHERS,
}

export const convertReportFunction = (
  reportFunction: ReportFunction
): string => {
  let word = '';
  switch (reportFunction) {
    case ReportFunction.SELECT:
      word = 'selecione uma opção';
      break;
    case ReportFunction.TENANCY:
      word = 'locação';
      break;
    case ReportFunction.LEASE:
      word = 'arrendamento';
      break;
    case ReportFunction.LENDING:
      word = 'comodato';
      break;
    case ReportFunction.ACQUISITION:
      word = 'aquisição';
      break;
    case ReportFunction.DONATION:
      word = 'doação';
      break;
    case ReportFunction.ALIENATION:
      word = 'alienação';
      break;
    case ReportFunction.GIVING_PAYMENT:
      word = 'dação em pagamento';
      break;
    case ReportFunction.EXCHANGE:
      word = 'permuta';
      break;
    case ReportFunction.GUARANTEE:
      word = 'garantia';
      break;
    case ReportFunction.ACCOUNTING:
      word = 'fins contábeis';
      break;
    case ReportFunction.INSURANCE:
      word = 'seguro';
      break;
    case ReportFunction.AUCTION:
      word = 'arrematação';
      break;
    case ReportFunction.ADJUDICATION:
      word = 'adjudicação';
      break;
    case ReportFunction.REPARATION:
      word = 'indenização';
      break;
    case ReportFunction.TAXATION:
      word = 'tributação';
      break;
    case ReportFunction.OTHERS:
      word = 'outros';
      break;
    default:
      word = '';
  }
  return word;
};

export enum ReportSource {
  ELEMENT = 1,
  REPORT,
}

export const convertReportSource = (enumType: ReportSource): string => {
  let word: string;
  switch (enumType) {
    case ReportSource.ELEMENT:
      word = 'elemento';
      break;
    case ReportSource.REPORT:
      word = 'relatório';
      break;
    default:
      word = '';
  }
  return word;
};

export enum ReportStandard {
  SELECT,
  HIGH_LUXURY,
  HIGH_PREDOMINANCE,
  NORMAL_HIGH_ASPECT,
  NORMAL_STRONG_PREDOMINANCE,
  NORMAL_LOW_ASPECT,
  LOW,
  MINIMUM,
}

export const convertReportStandard = (enumType: ReportStandard): string => {
  let word: string;
  switch (enumType) {
    case ReportStandard.SELECT:
      word = 'selecione uma opção';
      break;
    case ReportStandard.HIGH_LUXURY:
      word = 'alto (superior, luxo)';
      break;
    case ReportStandard.HIGH_PREDOMINANCE:
      word = 'alto (por predominância)';
      break;
    case ReportStandard.NORMAL_HIGH_ASPECT:
      word = 'normal (com aspecto de alto)';
      break;
    case ReportStandard.NORMAL_STRONG_PREDOMINANCE:
      word = 'normal (forte predominância)';
      break;
    case ReportStandard.NORMAL_LOW_ASPECT:
      word = 'normal (com aspecto de baixo)';
      break;
    case ReportStandard.LOW:
      word = 'baixo';
      break;
    case ReportStandard.MINIMUM:
      word = 'mínimo';
      break;
    default:
      word = '';
  }
  return word;
};

export enum Standard {
  SELECT,
  SIMPLE,
  NORMAL,
  HIGH,
}

export const convertStandard = (enumType: Standard): string => {
  let word: string;
  switch (enumType) {
    case Standard.SELECT:
      word = 'selecione uma opção';
      break;
    case Standard.SIMPLE:
      word = 'simples';
      break;
    case Standard.NORMAL:
      word = 'normal';
      break;
    case Standard.HIGH:
      word = 'alto';
      break;
    default:
      word = '';
  }
  return word;
};

export const convertStandard400K = (enumType: Standard): string => {
  let word: string;
  switch (enumType) {
    case Standard.SELECT:
      word = 'selecione uma opção';
      break;
    case Standard.SIMPLE:
      word = 'normal';
      break;
    case Standard.NORMAL:
      word = 'superior';
      break;
    case Standard.HIGH:
      word = 'fino';
      break;
    default:
      word = '';
  }
  return word;
};

export enum Status {
  ENTRANCE = 1,
  SCHEDULE,
  INSPECTION,
  SAMPLE_CREATION,
  CALCULATION,
  REPORT,
  REVISION,
  ARCHIVED,
  PEPT = 9,
}

export const convertStatus = (enumType: Status): string => {
  let word: string;
  switch (enumType) {
    case Status.ENTRANCE:
      word = 'entrada';
      break;
    case Status.SCHEDULE:
      word = 'agendamento';
      break;
    case Status.INSPECTION:
      word = 'em vistoria';
      break;
    case Status.SAMPLE_CREATION:
      word = 'criação de amostra';
      break;
    case Status.CALCULATION:
      word = 'processando cálculo';
      break;
    case Status.REPORT:
      word = 'elaboração de laudo';
      break;
    case Status.REVISION:
      word = 'revisão e aprovação';
      break;
    case Status.ARCHIVED:
      word = 'arquivado';
      break;
    case Status.PEPT:
      word = 'pendência técnica';
      break;
    default:
      word = '';
  }
  return word;
};

export const convertStatusUrl = (enumType: Status): string => {
  let word: string;
  switch (enumType) {
    case Status.ENTRANCE:
      word = 'edit';
      break;
    case Status.SCHEDULE:
      word = 'schedule';
      break;
    case Status.INSPECTION:
      word = 'inspection';
      break;
    case Status.SAMPLE_CREATION:
      word = 'sample';
      break;
    case Status.REPORT:
      word = 'report';
      break;
    case Status.REVISION:
      word = 'revision';
      break;
    case Status.PEPT:
      word = 'pept';
      break;
    case Status.CALCULATION:
      word = 'calculation';
      break;
    default:
      word = '';
  }
  return word;
};

export enum RegisterStage {
  IDENTIFICATION = 1,
  REGISTER_FORM,
  AUTHENTICATION,
  DOCUMENTS_VERIFICATION,
  IDENTITY_VERIFICATION,
}

export const convertRegisterStage = (enumType: RegisterStage): string => {
  let word: string;
  switch (enumType) {
    case RegisterStage.IDENTIFICATION:
      word = 'Identificação';
      break;
    case RegisterStage.REGISTER_FORM:
      word = 'Faça seu cadastro';
      break;
    case RegisterStage.AUTHENTICATION:
      word = 'Autenticação';
      break;
    case RegisterStage.DOCUMENTS_VERIFICATION:
      word = 'Documento com foto';
      break;
    case RegisterStage.IDENTITY_VERIFICATION:
      word = '';
      break;
    default:
      word = '';
  }
  return word;
};

export enum SampleElementSource {
  SAMPLER = 1,
  USER = 2,
  SAVED_SAMPLE = 3,
  WORK_ORDER = 4,
}

export enum StatusCode {
  OK = 0,
  ERROR = 3,
  NOT_FOUND = 4,
  PROCESSING = 5,
}

export enum StepTour {
  WELCOME,
  GUIDEDTOUR,
  PIPELINESTART,
  PIPELINEFINISH,
  ENTRANCESTART,
  ENTRANCEFINISH,
  PEPTSTART,
  PEPTFINISH,
  SCHEDULESTART,
  SCHEDULEFINISH,
  INSPECTIONSTART,
  INSPECTIONFINISH,
  SAMPLECREATESTART,
  SAMPLECREATEFINISH,
  CALCULATIONSTART,
  CALCULATIONFINISH,
  REPORTSTART,
  REPORTFINISH,
  REVISIONSTART,
  REVISIONFINISH,
  ARCHIVEDSTART,
  ARCHIVEDFINISH,
  FORMSTART,
  FORMFINISH,
  CONFIGURATIONSTART,
  CONFIGURATIONFINISH,
  AGENDASTART,
  AGENDAFINISH,
  ERRORREPORTSTART,
  ERRORREPORTFINISH,
  SAMPLEADDNEWELEMENT,
}

export enum Treatment {
  SELECT,
  INFERENCE,
  FACTORS,
}

export const convertTreatment = (enumType: Treatment): string => {
  let word = '';
  switch (enumType) {
    case Treatment.SELECT:
      word = 'selecione uma opção';
      break;
    case Treatment.INFERENCE:
      word = 'Inferência';
      break;
    case Treatment.FACTORS:
      word = 'Fatores';
      break;
    default:
      word = '';
  }
  return word;
};

export enum Access {
  SELECT,
  GOOD,
  DIFFICULT,
  EXCELLENT,
  REASONABLE,
}

export const convertAccess = (enumType: Access): string => {
  let word: string;
  switch (enumType) {
    case Access.SELECT:
      word = 'selecione uma opção';
      break;
    case Access.GOOD:
      word = 'bom';
      break;
    case Access.DIFFICULT:
      word = 'difícil';
      break;
    case Access.EXCELLENT:
      word = 'ótimo';
      break;
    case Access.REASONABLE:
      word = 'razoável';
      break;
    default:
      word = '';
  }
  return word;
};

export enum Paving {
  SELECT,
  ASPHALT,
  GRAVEL,
  BLOCK,
  CONCRETE,
  COBBLESTONE,
  DIRT_ROAD,
}

export const convertPaving = (enumType: Paving): string => {
  let word: string;
  switch (enumType) {
    case Paving.SELECT:
      word = 'selecione uma opção';
      break;
    case Paving.ASPHALT:
      word = 'asfalto';
      break;
    case Paving.GRAVEL:
      word = 'brita compacta ou cascalho';
      break;
    case Paving.BLOCK:
      word = 'bloquete';
      break;
    case Paving.CONCRETE:
      word = 'concreto';
      break;
    case Paving.COBBLESTONE:
      word = 'paralelepípedo';
      break;
    case Paving.DIRT_ROAD:
      word = 'terra batida';
      break;
    default:
      word = '';
  }
  return word;
};

export enum YesOrNo {
  SELECT,
  YES,
  NO,
}

export const convertYesOrNo = (enumType: YesOrNo): string => {
  let word: string;
  switch (enumType) {
    case YesOrNo.SELECT:
      word = 'selecione uma opção';
      break;
    case YesOrNo.YES:
      word = 'sim';
      break;
    case YesOrNo.NO:
      word = 'não';
      break;
    default:
      word = '';
  }
  return word;
};

export enum Format {
  SELECT,
  IRREGULAR,
  REGULAR,
}

export const convertFormat = (enumType: Format): string => {
  let word: string;
  switch (enumType) {
    case Format.SELECT:
      word = 'selecione uma opção';
      break;
    case Format.IRREGULAR:
      word = 'irregular';
      break;
    case Format.REGULAR:
      word = 'regular';
      break;
    default:
      word = '';
  }
  return word;
};

export enum Drainage {
  SELECT,
  FLOODABLE,
  SWAMPY,
  DRY,
  OTHER,
}

export const convertDrainage = (enumType: Drainage): string => {
  let word: string;
  switch (enumType) {
    case Drainage.SELECT:
      word = 'selecione uma opção';
      break;
    case Drainage.FLOODABLE:
      word = 'alagável';
      break;
    case Drainage.SWAMPY:
      word = 'brejoso';
      break;
    case Drainage.DRY:
      word = 'seco';
      break;
    case Drainage.OTHER:
      word = 'outra';
      break;
    default:
      word = '';
  }
  return word;
};

export enum Greide {
  SELECT,
  BELOW,
  ABOVE,
  SAME_LEVEL,
}

export const convertGreide = (enumType: Greide): string => {
  let word: string;
  switch (enumType) {
    case Greide.SELECT:
      word = 'selecione uma opção';
      break;
    case Greide.BELOW:
      word = 'abaixo';
      break;
    case Greide.ABOVE:
      word = 'acima';
      break;
    case Greide.SAME_LEVEL:
      word = 'mesmo nível';
      break;
    default:
      word = '';
  }
  return word;
};

export enum Fence {
  SELECT,
  NO_FENCE,
  WIRE,
  SCREEN,
  RAILING,
  WALL,
  OTHER,
}

export const convertFence = (enumType: Fence): string => {
  let word: string;
  switch (enumType) {
    case Fence.SELECT:
      word = 'selecione uma opção';
      break;
    case Fence.NO_FENCE:
      word = 'sem cercamento';
      break;
    case Fence.WIRE:
      word = 'arame';
      break;
    case Fence.SCREEN:
      word = 'tela';
      break;
    case Fence.RAILING:
      word = 'gradil';
      break;
    case Fence.WALL:
      word = 'muro';
      break;
    case Fence.OTHER:
      word = 'outro';
      break;
    default:
      word = '';
  }
  return word;
};

export enum Absorption {
  SELECT,
  DIFFICULT,
  EASY,
  EXTREMELY_EASY,
}

export const convertAbsorptionByTheMarket = (enumType: Absorption): string => {
  let word: string;
  switch (enumType) {
    case Absorption.SELECT:
      word = 'selecione uma opção';
      break;
    case Absorption.DIFFICULT:
      word = 'difícil';
      break;
    case Absorption.EASY:
      word = 'fácil';
      break;
    case Absorption.EXTREMELY_EASY:
      word = 'extremamente fácil';
      break;
    default:
      word = '';
  }
  return word;
};

export enum MarketPerformance {
  SELECT,
  APPARENTLY_FORGOTTEN,
  NOT_FEATURED,
  APPARENTLY_LOW,
}

export const convertMarketPerformance = (
  enumType: MarketPerformance
): string => {
  let word: string;
  switch (enumType) {
    case MarketPerformance.SELECT:
      word = 'selecione uma opção';
      break;
    case MarketPerformance.APPARENTLY_FORGOTTEN:
      word = 'aparentemente esquecido';
      break;
    case MarketPerformance.NOT_FEATURED:
      word = 'sem destaque';
      break;
    case MarketPerformance.APPARENTLY_LOW:
      word = 'aparentemente baixo';
      break;
    default:
      word = '';
  }
  return word;
};

export enum Offers {
  SELECT,
  APPARENTLY_HIGH,
  NOT_FEATURED,
  APPARENTLY_LOW,
}

export const convertOffers = (enumType: Offers): string => {
  let word: string;
  switch (enumType) {
    case Offers.SELECT:
      word = 'selecione uma opção';
      break;
    case Offers.APPARENTLY_HIGH:
      word = 'aparentemente alto';
      break;
    case Offers.NOT_FEATURED:
      word = 'sem destaque';
      break;
    case Offers.APPARENTLY_LOW:
      word = 'aparentemente baixo';
      break;
    default:
      word = '';
  }
  return word;
};

export enum Liquidity {
  SELECT,
  PROBABLY_HIGH,
  NOT_FEATURED,
  PROBABLY_LOW,
}

export const convertLiquidity = (enumType: Liquidity): string => {
  let word: string;
  switch (enumType) {
    case Liquidity.SELECT:
      word = 'selecione uma opção';
      break;
    case Liquidity.PROBABLY_HIGH:
      word = 'provavelmente alta';
      break;
    case Liquidity.NOT_FEATURED:
      word = 'sem destaque';
      break;
    case Liquidity.PROBABLY_LOW:
      word = 'provavelmente baixa';
      break;
    default:
      word = '';
  }
  return word;
};

export enum ReportConservation {
  SELECT,
  GOOD,
  UNDER_CONSTRUCTION,
  AVERAGE,
  BAD,
}

export const convertReportConservation = (
  enumType: ReportConservation
): string => {
  let word: string;
  switch (enumType) {
    case ReportConservation.SELECT:
      word = 'selecione uma opção';
      break;
    case ReportConservation.GOOD:
      word = 'bom';
      break;
    case ReportConservation.UNDER_CONSTRUCTION:
      word = 'em construção';
      break;
    case ReportConservation.AVERAGE:
      word = 'regular';
      break;
    case ReportConservation.BAD:
      word = 'ruim';
      break;
    default:
      word = '';
  }
  return word;
};

export enum ReportCondominiumType {
  SELECT,
  APARTMENT,
  HOUSE,
}

export const convertReportCondominiumType = (
  enumType: ReportCondominiumType
): string => {
  let word: string;
  switch (enumType) {
    case ReportCondominiumType.SELECT:
      word = 'selecione uma opção';
      break;
    case ReportCondominiumType.APARTMENT:
      word = 'apartamento';
      break;
    case ReportCondominiumType.HOUSE:
      word = 'casa';
      break;
    default:
      word = '';
  }
  return word;
};

export enum ReportPosition {
  SELECT,
  ISOLATED_FRONT,
  ISOLATED_CENTER,
  SEMI_DETACHED_ON_ONE_SIDE,
  SEMI_DETACHED_ON_THE_SIDES,
  NEAR_THE_BACK,
  NEXT_TO_A_SIDE,
  NEXT_TO_THE_SIDES,
  IT_OCCUPIES_THE_ENTIRE_LAND,
}

export const convertReportPosition = (enumType: ReportPosition): string => {
  let word: string;
  switch (enumType) {
    case ReportPosition.SELECT:
      word = 'selecione uma opção';
      break;
    case ReportPosition.ISOLATED_FRONT:
      word = 'isolada / frente do terreno';
      break;
    case ReportPosition.ISOLATED_CENTER:
      word = 'isolada / centro do terreno';
      break;
    case ReportPosition.SEMI_DETACHED_ON_ONE_SIDE:
      word = 'geminada em uma lateral';
      break;
    case ReportPosition.SEMI_DETACHED_ON_THE_SIDES:
      word = 'geminada nas laterais';
      break;
    case ReportPosition.NEAR_THE_BACK:
      word = 'junto aos fundos';
      break;
    case ReportPosition.NEXT_TO_A_SIDE:
      word = 'junto a uma lateral';
      break;
    case ReportPosition.NEXT_TO_THE_SIDES:
      word = 'junto às laterais';
      break;
    case ReportPosition.IT_OCCUPIES_THE_ENTIRE_LAND:
      word = 'ocupa todo o terreno';
      break;
    default:
      word = '';
  }
  return word;
};

export enum StateOfConservation {
  SELECT,
  GOOD,
  REGULAR,
  BAD,
  IN_IMPLEMENTATION,
}

export const convertStateOfConservation = (
  enumType: StateOfConservation
): string => {
  let word: string;
  switch (enumType) {
    case StateOfConservation.SELECT:
      word = 'selecione uma opção';
      break;
    case StateOfConservation.GOOD:
      word = 'bom';
      break;
    case StateOfConservation.REGULAR:
      word = 'regular';
      break;
    case StateOfConservation.BAD:
      word = 'ruim';
      break;
    case StateOfConservation.IN_IMPLEMENTATION:
      word = 'em implantação';
      break;
    default:
      word = '';
  }
  return word;
};

export enum FinishingStandard {
  SELECT,
  HIGH,
  LOW,
  MINIMUM,
  NORMAL_MEDIUM,
}

export const convertFinishingStandard = (
  enumType: FinishingStandard
): string => {
  let word: string;
  switch (enumType) {
    case FinishingStandard.SELECT:
      word = 'selecione uma opção';
      break;
    case FinishingStandard.HIGH:
      word = 'alto';
      break;
    case FinishingStandard.LOW:
      word = 'baixo';
      break;
    case FinishingStandard.MINIMUM:
      word = 'mínimo';
      break;
    case FinishingStandard.NORMAL_MEDIUM:
      word = 'normal / médio';
      break;
    default:
      word = '';
  }
  return word;
};

export enum Location {
  SELECT,
  GOOD,
  REGULAR,
  BAD,
  EXCELLENT,
}

export const convertLocation = (enumType: Location): string => {
  let word: string;
  switch (enumType) {
    case Location.SELECT:
      word = 'selecione uma opção';
      break;
    case Location.GOOD:
      word = 'boa';
      break;
    case Location.REGULAR:
      word = 'regular';
      break;
    case Location.BAD:
      word = 'ruim';
      break;
    case Location.EXCELLENT:
      word = 'ótima';
      break;
    default:
      word = '';
  }
  return word;
};

export enum Deployment {
  SELECT,
  ISOLATED,
  CONDOMINIUM,
}

export const convertDeployment = (enumType: Deployment): string => {
  let word: string;
  switch (enumType) {
    case Deployment.SELECT:
      word = 'selecione uma opção';
      break;
    case Deployment.ISOLATED:
      word = 'isolada';
      break;
    case Deployment.CONDOMINIUM:
      word = 'condomínio';
      break;
    default:
      word = '';
  }
  return word;
};

export enum Topography {
  SELECT,
  FLAT,
  UPHILL_GREATER_THAN_TEN,
  DOWNHILL_LESS_THAN_TEN,
}

export const convertTopography = (enumType: Topography): string => {
  let word: string;
  switch (enumType) {
    case Topography.SELECT:
      word = 'selecione uma opção';
      break;
    case Topography.FLAT:
      word = 'plano';
      break;
    case Topography.UPHILL_GREATER_THAN_TEN:
      word = 'aclive maior que 10%';
      break;
    case Topography.DOWNHILL_LESS_THAN_TEN:
      word = 'declive menor que 10%';
      break;
    default:
      word = '';
  }
  return word;
};

export enum Situation {
  SELECT,
  CORNER,
  MIDCOURT,
}

export const convertSituation = (enumType: Situation): string => {
  let word: string;
  switch (enumType) {
    case Situation.SELECT:
      word = 'selecione uma opção';
      break;
    case Situation.CORNER:
      word = 'esquina';
      break;
    case Situation.MIDCOURT:
      word = 'meio de quadra';
      break;
    default:
      word = '';
  }
  return word;
};

export enum Foundation {
  SELECT,
  I,
  II,
  III,
}

export const convertFoundation = (enumType: Foundation): string => {
  let word: string;
  switch (enumType) {
    case Foundation.SELECT:
      word = 'selecione uma opção';
      break;
    case Foundation.I:
      word = 'I';
      break;
    case Foundation.II:
      word = 'II';
      break;
    case Foundation.III:
      word = 'III';
      break;
    default:
      word = '';
  }
  return word;
};

export enum BDI {
  SELECT,
  CALCULATED,
  JUSTIFIED,
  ARBITRATED,
}

export const convertBDI = (enumType: BDI): string => {
  let word: string;
  switch (enumType) {
    case BDI.SELECT:
      word = 'selecione uma opção';
      break;
    case BDI.CALCULATED:
      word = 'calculado';
      break;
    case BDI.JUSTIFIED:
      word = 'justificado';
      break;
    case BDI.ARBITRATED:
      word = 'arbitrado';
      break;
    default:
      word = '';
  }
  return word;
};

export enum VariableType {
  SELECT,
  DEPENDENT,
  INDEPENDENT,
}

export const convertVariableType = (enumType: VariableType): string => {
  let word: string;
  switch (enumType) {
    case VariableType.SELECT:
      word = 'selecione uma opção';
      break;
    case VariableType.DEPENDENT:
      word = 'dependente';
      break;
    case VariableType.INDEPENDENT:
      word = 'independente';
      break;
    default:
      word = '';
  }
  return word;
};

export enum VariableGrowth {
  SELECT,
  POSITIVE,
  NEGATIVE,
}

export const convertVariableGrowth = (enumType: VariableGrowth): string => {
  let word: string;
  switch (enumType) {
    case VariableGrowth.SELECT:
      word = 'selecione uma opção';
      break;
    case VariableGrowth.POSITIVE:
      word = 'positiva';
      break;
    case VariableGrowth.NEGATIVE:
      word = 'negativa';
      break;
    default:
      word = '';
  }
  return word;
};

export enum EvaluationMethodology {
  SELECT,
  MCDDM,
  SMDNO,
  OTHERS,
}

export const convertEvaluationMethodology = (
  enumType: EvaluationMethodology
): string => {
  let word: string;
  switch (enumType) {
    case EvaluationMethodology.SELECT:
      word = 'selecione uma opção';
      break;
    case EvaluationMethodology.MCDDM:
      word = 'MCDDM';
      break;
    case EvaluationMethodology.SMDNO:
      word = 'SMDNO';
      break;
    case EvaluationMethodology.OTHERS:
      word = 'outras metodologias';
      break;
    default:
      word = '';
  }
  return word;
};

export enum PurposeEvaluation {
  SELECT,
  MARKET_VALUE,
  MARKET_VALUE_IMMEDIATE_LIQUIDATION,
}

export const convertPurposeEvaluation = (
  enumType: PurposeEvaluation
): string => {
  let word: string;
  switch (enumType) {
    case PurposeEvaluation.SELECT:
      word = 'selecione uma opção';
      break;
    case PurposeEvaluation.MARKET_VALUE:
      word = 'Valor de mercado';
      break;
    case PurposeEvaluation.MARKET_VALUE_IMMEDIATE_LIQUIDATION:
      word = 'Valor de mercado + Valor de liquidação imediata';
      break;
    default:
      word = '';
  }
  return word;
};

export enum TypeOfOccupation {
  SELECT,
  DESOCUPADO,
  CEDIDO_COMODATO,
  INVADIDO,
  LOCADO_ARRENDADO,
  OCUPADO,
}

export const convertTypeOfOccupation = (enumType: TypeOfOccupation): string => {
  let word: string;
  switch (enumType) {
    case TypeOfOccupation.SELECT:
      word = 'selecione uma opção';
      break;
    case TypeOfOccupation.DESOCUPADO:
      word = 'desocupado';
      break;
    case TypeOfOccupation.CEDIDO_COMODATO:
      word = 'cedido / comodato';
      break;
    case TypeOfOccupation.INVADIDO:
      word = 'invadido';
      break;
    case TypeOfOccupation.LOCADO_ARRENDADO:
      word = 'locado / arrendado';
      break;
    case TypeOfOccupation.OCUPADO:
      word = 'ocupado';
      break;
    default:
      word = '';
  }
  return word;
};

export enum TypeOfProperty {
  SELECT,
  CONVENCIONAL,
  COBERTURA,
  DUPLEX,
  GARDEN,
  KITNET,
  LOFT,
}

export const convertTypeOfProperty = (enumType: TypeOfProperty): string => {
  let word: string;
  switch (enumType) {
    case TypeOfProperty.SELECT:
      word = 'selecione uma opção';
      break;
    case TypeOfProperty.CONVENCIONAL:
      word = 'convencional';
      break;
    case TypeOfProperty.COBERTURA:
      word = 'cobertura';
      break;
    case TypeOfProperty.DUPLEX:
      word = 'duplex';
      break;
    case TypeOfProperty.GARDEN:
      word = 'garden';
      break;
    case TypeOfProperty.KITNET:
      word = 'kitnet';
      break;
    case TypeOfProperty.LOFT:
      word = 'loft';
      break;
    default:
      word = '';
  }
  return word;
};

export enum TerrainPosition {
  SELECT,
  HALF_BLOCK,
  CORNER,
  LANDSCAPED,
  FULL_BLOCK,
}

export const convertTerrainPosition = (enumType: TerrainPosition): string => {
  let word: string;
  switch (enumType) {
    case TerrainPosition.SELECT:
      word = 'selecione uma opção';
      break;
    case TerrainPosition.HALF_BLOCK:
      word = 'meio de quadra';
      break;
    case TerrainPosition.CORNER:
      word = 'esquina';
      break;
    case TerrainPosition.LANDSCAPED:
      word = 'encravado';
      break;
    case TerrainPosition.FULL_BLOCK:
      word = 'quadra inteira';
      break;
    default:
      word = '';
  }
  return word;
};
