export interface IMicroregionData {
  id: number;
  city: string;
}

export interface ICoverageTableData {
  id: number;
  notice_name: string;
  uf: number;
  coverage: {
    id: number;
    city: string;
    microregions: IMicroregionData[];
  };
}

export const coverageData: ICoverageTableData[] = [
  {
    id: 1,
    notice_name: '0001/2024-0001',
    uf: 25,
    coverage: {
      id: 1,
      city: 'São Paulo',
      microregions: [
        {
          id: 1,
          city: 'Guarulhos',
        },
        {
          id: 2,
          city: 'Barueri',
        },
        {
          id: 3,
          city: 'Santo Adré',
        },
      ],
    },
  },
  {
    id: 2,
    notice_name: '0002/2024-0002',
    uf: 25,
    coverage: {
      id: 1,
      city: 'São Paulo',
      microregions: [
        {
          id: 1,
          city: 'Guarulhos',
        },
        {
          id: 2,
          city: 'Barueri',
        },
        {
          id: 3,
          city: 'Santo Adré',
        },
      ],
    },
  },
  {
    id: 3,
    notice_name: '0003/2024-0003',
    uf: 25,
    coverage: {
      id: 1,
      city: 'São Paulo',
      microregions: [
        {
          id: 1,
          city: 'Guarulhos',
        },
        {
          id: 2,
          city: 'Barueri',
        },
        {
          id: 3,
          city: 'Santo Adré',
        },
      ],
    },
  },
  {
    id: 4,
    notice_name: '0004/2024-0004',
    uf: 25,
    coverage: {
      id: 1,
      city: 'São Paulo',
      microregions: [
        {
          id: 1,
          city: 'Guarulhos',
        },
        {
          id: 2,
          city: 'Barueri',
        },
        {
          id: 3,
          city: 'Santo Adré',
        },
      ],
    },
  },
];
