import {
  handleUrl,
  myHeadersWithToken,
  myHeadersWithTokenContentType,
} from '../../global';
import {
  SendMessageData,
  UnreadMessagesCountProps,
  WorkOrderMessagesProps,
  DetailProps,
  HiveRequestData,
  HiveRequestProps,
} from './types';

export const checkHiveRequest = (id: number): Promise<HiveRequestProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${id}/hive-requests`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const handleHiveRequest = (
  id: number,
  data: HiveRequestData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PATCH',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${id}/hive-requests`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getWorkOrderMessages = (
  osId: number,
  page: number,
  size: number
): Promise<WorkOrderMessagesProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${osId}/hive-messages?page=${page}&size=${size}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const sendWorkOrderMessage = (
  osId: number,
  data: SendMessageData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${osId}/hive-messages`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getUnreadMessagesCount = (
  osId: number
): Promise<UnreadMessagesCountProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${osId}/hive-messages/unread-count?sent_by=hive`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const updateMessagesAsRead = (osId: number): Promise<DetailProps> => {
  const data = {
    sender: 'hive',
  };
  const requestOptions = {
    method: 'PATCH',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${osId}/hive-messages/read`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};
