/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
import {
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { IconAddMS } from '../../../../../../constants/icons';
import { useAppraisalInformation } from '../../../../../../hooks/useAppraisalInformation';
import { OutlinedButton, StyledTableCellBody } from '../../../styles';

export interface InternalDivisionDataProps {
  room_type: string;
  quantity: number;
}

interface TableInternalDivisionProps {
  internalDivision: InternalDivisionDataProps[] | undefined;
}

export function TableInternalDivision({
  internalDivision,
}: TableInternalDivisionProps): JSX.Element {
  const [allFilled, setAllFilled] = useState(false);

  const { internal_division, setInternalDivision } = useAppraisalInformation();
  const [data, setData] =
    useState<InternalDivisionDataProps[]>(internal_division);

  const handleInputChange = (
    index: number,
    field: keyof InternalDivisionDataProps,
    value: string | number
  ): void => {
    const updatedInputs = [...data];
    updatedInputs[index] = { ...updatedInputs[index], [field]: value };
    setData(updatedInputs);
  };

  const handleAddRow = (): void => {
    setData((prevData) => [...prevData, { room_type: '', quantity: 0 }]);
  };

  useEffect(() => {
    if (internalDivision !== undefined) {
      setData(internalDivision);
    }
  }, [internalDivision]);

  useEffect(() => {
    const areAllInputsFilled = data.every(
      (input) => input.room_type.trim() !== '' && input.quantity.valueOf() !== 0
    );
    setAllFilled(areAllInputsFilled);

    if (allFilled) {
      setInternalDivision(data);
    }
  }, [allFilled, data, setInternalDivision]);

  return (
    <TableContainer>
      <Table>
        <TableRow sx={{ backgroundColor: '#F4EEF6', border: '1px solid #ccc' }}>
          <TableCell align="center" colSpan={3}>
            divisão interna
          </TableCell>
        </TableRow>
        <TableRow sx={{ backgroundColor: '#E9E1ED', border: '1px solid #ccc' }}>
          <TableCell
            align="center"
            sx={{ width: '50%', border: '1px solid #ccc' }}
          >
            tipo de cômodo
          </TableCell>
          <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
            quantidade
          </TableCell>
        </TableRow>
        {data.map((item, index) => (
          <TableRow key={index}>
            <StyledTableCellBody>
              <TextField
                multiline
                value={item.room_type}
                onChange={(e) =>
                  handleInputChange(index, 'room_type', e.target.value)
                }
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <TextField
                multiline
                value={item.quantity}
                onChange={(e) =>
                  handleInputChange(index, 'quantity', Number(e.target.value))
                }
              />
            </StyledTableCellBody>
          </TableRow>
        ))}
        <Grid item marginTop={2}>
          <OutlinedButton onClick={handleAddRow}>{IconAddMS}</OutlinedButton>
        </Grid>
      </Table>
    </TableContainer>
  );
}
