import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { AttachmentFilesProvider } from './context/attachmentFiles';
import { GlobalProvider } from './context/global';
import { RegistrationIdentification } from './context/registrationIdentification';
import { AppraisalInformationProvider } from './hooks/useAppraisalInformation';
import { ReportCompletionProvider } from './hooks/useReportCompletion';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <GlobalProvider>
      <AppraisalInformationProvider>
        <ReportCompletionProvider>
          <RegistrationIdentification>
            <AttachmentFilesProvider>
              <HubspotProvider>
                <App />
              </HubspotProvider>
            </AttachmentFilesProvider>
          </RegistrationIdentification>
        </ReportCompletionProvider>
      </AppraisalInformationProvider>
    </GlobalProvider>
  </React.StrictMode>
);

reportWebVitals();
