import Color from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useEffect } from 'react';

import { ReaderBox } from './styles';

interface MessageReaderProps {
  messageRichText: string;
}

export function MessageReader({
  messageRichText,
}: MessageReaderProps): JSX.Element {
  const editor = useEditor({
    extensions: [StarterKit, TextStyle, Color],
    content: '',
  });

  useEffect(() => {
    if (editor && messageRichText) {
      try {
        const parsedContent = JSON.parse(messageRichText);
        editor.commands.setContent(parsedContent);
      } catch (error) {
        editor.commands.setContent({
          type: 'doc',
          content: [
            {
              type: 'paragraph',
              content: [
                {
                  type: 'text',
                  text: 'Mensagem inválida',
                },
              ],
            },
          ],
        });
      }
    }
  }, [editor, messageRichText]);
  return (
    <ReaderBox>
      <EditorContent
        editor={editor}
        className="editor-reader"
        style={{
          fontSize: '16px',
          padding: '12px 0',
        }}
      />
    </ReaderBox>
  );
}
