/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
import { Grid, Paper } from '@mui/material';
import { useState } from 'react';

import { WorkOrderData } from '../../../api/workOrders/types';
import { Constants } from '../../../constants/report';
import { useAccordion } from '../../../hooks/useAccordion';
import { useReportCompletion } from '../../../hooks/useReportCompletion';
import { SectionBox } from '../../UI/Box';
import { AccordionTitle } from '../AccordionTitle';
import { WorkOrderProgressBar } from '../ProgressBar';
import { AdditionalInformation } from './InputsBySections/additional-information';
import { AppraisalInformation } from './InputsBySections/appraisal-information';
import { Attachments } from './InputsBySections/attachments';
import { Condominium } from './InputsBySections/condominium';
import { Diagnostic } from './InputsBySections/diagnostic';
import { EvaluationResult } from './InputsBySections/evaluation-result';
import { EvaluationSummary } from './InputsBySections/evaluation-summary';
import { FinalRemarks } from './InputsBySections/final-remarks';
import { GeneralConditions } from './InputsBySections/general-conditions';
import { ImprovementsContruction } from './InputsBySections/improvements-construction';
import { Methodology } from './InputsBySections/methodology';
import { Microregion } from './InputsBySections/microregion';
import { Pictures } from './InputsBySections/pictures';
import { ReportInspection } from './InputsBySections/report-inspection';
import { Terrain } from './InputsBySections/terrain';
import { Variables } from './InputsBySections/variables';
import { WarrantyStatements } from './InputsBySections/warranty-statements';
import { ReportTitle } from './styles';

interface StaticalReport {
  propertyData: WorkOrderData | undefined;
}

export function StatisticalReport({
  propertyData,
}: StaticalReport): JSX.Element {
  const [expandEleven, setExpandEleven] = useState(false);
  const [expandTwelve, setExpandTwelve] = useState(false);
  const [expandThirteen, setExpandThirteen] = useState(false);
  const [expandFourteen, setExpandFourteen] = useState(false);
  const [expandFifteen, setExpandFifteen] = useState(false);
  const [expandSixteen, setExpandSixteen] = useState(false);
  const [expandSeventeen, setExpandSeventeen] = useState(false);

  const {
    expandOne,
    setExpandOne,
    expandTwo,
    setExpandTwo,
    expandThree,
    setExpandThree,
    expandFour,
    setExpandFour,
    expandFive,
    setExpandFive,
    expandSix,
    setExpandSix,
    expandSeven,
    setExpandSeven,
    expandEight,
    setExpandEight,
    expandNine,
    setExpandNine,
    expandTen,
    setExpandTen,
  } = useAccordion();

  const { reportCompletion } = useReportCompletion();

  return (
    <Grid container rowGap={1.5} component={Paper} sx={{ padding: '54px' }}>
      <Grid item xs={12} sx={{ marginBottom: '40px' }}>
        <ReportTitle>{Constants.reportTitle}</ReportTitle>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.methodology}>
          <SectionBox>
            <AccordionTitle
              title="Metodologia da avaliação"
              openAccordion={expandOne}
              setOpenAccordion={setExpandOne}
            />
            {expandOne && <Methodology propertyData={propertyData} />}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.variables}>
          <SectionBox>
            <AccordionTitle
              title="Variáveis"
              openAccordion={expandTwo}
              setOpenAccordion={setExpandTwo}
            />
            {expandTwo && <Variables propertyData={propertyData} />}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.evaluationSummary}>
          <SectionBox>
            <AccordionTitle
              title="Resumo da avaliação"
              openAccordion={expandThree}
              setOpenAccordion={setExpandThree}
            />
            {expandThree && <EvaluationSummary propertyData={propertyData} />}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.evaluationResult}>
          <SectionBox>
            <AccordionTitle
              title="Resultado da avaliação"
              openAccordion={expandFour}
              setOpenAccordion={setExpandFour}
            />
            {expandFour && <EvaluationResult propertyData={propertyData} />}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.attachments}>
          <SectionBox>
            <AccordionTitle
              title="Anexos"
              openAccordion={expandFive}
              setOpenAccordion={setExpandFive}
            />
            {expandFive && <Attachments />}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.images}>
          <SectionBox>
            <AccordionTitle
              title="Imagens"
              openAccordion={expandSix}
              setOpenAccordion={setExpandSix}
            />
            {expandSix && <Pictures />}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.appraisalInformation}>
          <SectionBox>
            <AccordionTitle
              title="Imóvel avaliando"
              openAccordion={expandSeven}
              setOpenAccordion={setExpandSeven}
            />
            {expandSeven && (
              <AppraisalInformation propertyData={propertyData} />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.microregion}>
          <SectionBox>
            <AccordionTitle
              title="Microrregião do avaliando"
              openAccordion={expandEight}
              setOpenAccordion={setExpandEight}
            />
            {expandEight && <Microregion propertyData={propertyData} />}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.terrain}>
          <SectionBox>
            <AccordionTitle
              title="Terreno"
              openAccordion={expandNine}
              setOpenAccordion={setExpandNine}
            />
            {expandNine && <Terrain propertyData={propertyData} />}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.condominium}>
          <SectionBox>
            <AccordionTitle
              title="Condomínio"
              openAccordion={expandTen}
              setOpenAccordion={setExpandTen}
            />
            {expandTen && <Condominium propertyData={propertyData} />}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.improvements}>
          <SectionBox>
            <AccordionTitle
              title="Benfeitorias / Construção"
              openAccordion={expandEleven}
              setOpenAccordion={setExpandEleven}
            />
            {expandEleven && (
              <ImprovementsContruction propertyData={propertyData} />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.diagnostic}>
          <SectionBox>
            <AccordionTitle
              title="Diagnóstico de mercado"
              openAccordion={expandTwelve}
              setOpenAccordion={setExpandTwelve}
            />
            {expandTwelve && <Diagnostic propertyData={propertyData} />}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.generalConditions}>
          <SectionBox>
            <AccordionTitle
              title="Condições gerais"
              openAccordion={expandThirteen}
              setOpenAccordion={setExpandThirteen}
            />
            {expandThirteen && (
              <GeneralConditions propertyData={propertyData} />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.inspection}>
          <SectionBox>
            <AccordionTitle
              title="Vistoria"
              openAccordion={expandFourteen}
              setOpenAccordion={setExpandFourteen}
            />
            {expandFourteen && <ReportInspection propertyData={propertyData} />}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.warrantyStatements}>
          <SectionBox>
            <AccordionTitle
              title="Manifestações de garantia"
              openAccordion={expandFifteen}
              setOpenAccordion={setExpandFifteen}
            />
            {expandFifteen && (
              <WarrantyStatements propertyData={propertyData} />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.additionalInfo}>
          <SectionBox>
            <AccordionTitle
              title="Informações complementares de unidades autônomas incluídas na transação (exclusivo para fins cartorários)"
              openAccordion={expandSixteen}
              setOpenAccordion={setExpandSixteen}
            />
            {expandSixteen && (
              <AdditionalInformation propertyData={propertyData} />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar
          complete={reportCompletion.finalRemarks}
          lastAccordion
        >
          <SectionBox>
            <AccordionTitle
              title="Observações finais"
              openAccordion={expandSeventeen}
              setOpenAccordion={setExpandSeventeen}
            />
            {expandSeventeen && <FinalRemarks propertyData={propertyData} />}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
    </Grid>
  );
}
