/* eslint-disable @typescript-eslint/no-explicit-any */
import { CircularProgress, Input } from '@mui/material';
import { FormEvent } from 'react';

import {
  IconFormatBoldMS,
  IconFormatItalicMS,
  IconFormatListBulletedMS,
  IconSendMS,
} from '../../../../../constants/icons';
import {
  ButtonsContainer,
  MenuContainer,
  SendButton,
  StyledIconButton,
} from './styles';

interface RichTextMenuProps {
  editor: any;
  loading: boolean;
}

export function RichTextMenu({
  editor,
  loading,
}: RichTextMenuProps): JSX.Element | null {
  return (
    <MenuContainer>
      <ButtonsContainer>
        <StyledIconButton
          disableTouchRipple
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          selected={editor.isActive('bold')}
          sx={{
            span: {
              fontWeight: '600',
            },
          }}
        >
          {IconFormatBoldMS}
        </StyledIconButton>
        <StyledIconButton
          disableTouchRipple
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          selected={editor.isActive('italic')}
        >
          {IconFormatItalicMS}
        </StyledIconButton>
        <StyledIconButton
          disableTouchRipple
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          disabled={!editor.can().chain().focus().toggleBulletList().run()}
          selected={editor.isActive('bulletList')}
        >
          {IconFormatListBulletedMS}
        </StyledIconButton>
        <Input
          sx={{ height: 20, width: 50 }}
          disableUnderline
          type="color"
          onInput={(event: FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            editor.chain().focus().setColor(target.value).run();
          }}
          value={editor.getAttributes('textStyle').color}
        />
      </ButtonsContainer>
      <SendButton form="send-message" type="submit" disabled={loading}>
        {loading ? <CircularProgress size={36} /> : IconSendMS}
      </SendButton>
    </MenuContainer>
  );
}
