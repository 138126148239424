/* eslint-disable max-lines */
import {
  Absorption,
  Access,
  BDI,
  ClientKind,
  Concept,
  ConservationState,
  convertAbsorptionByTheMarket,
  convertAccess,
  convertBDI,
  convertClientKind,
  convertConcept,
  convertConservationState,
  convertDeployment,
  convertDrainage,
  convertEvaluationMethodology,
  convertEvaluationType,
  convertEvaluationTypeBank,
  convertFence,
  convertFinishingStandard,
  convertFormat,
  convertFormPropertyType,
  convertFoundation,
  convertGreide,
  convertLiquidity,
  convertLocation,
  convertMarketPerformance,
  convertOffers,
  convertPaving,
  convertProfileUsers,
  convertPropertyType,
  convertPropertyUse,
  convertPurposeEvaluation,
  convertQuestionType,
  convertRegistrationUf,
  convertReportCondominiumPropertyUse,
  convertReportCondominiumType,
  convertReportConservation,
  convertReportFunction,
  convertReportGoal,
  convertReportPosition,
  convertReportPropertyUse,
  convertReportStandard,
  convertSituation,
  convertStandard,
  convertStandard400K,
  convertStateOfConservation,
  convertTerrainPosition,
  convertTopography,
  convertTreatment,
  convertTypeOfOccupation,
  convertTypeOfProperty,
  convertVariableGrowth,
  convertVariableType,
  convertYesOrNo,
  Deployment,
  Drainage,
  EvaluationMethodology,
  EvaluationType,
  EvaluationTypeBank,
  Fence,
  FinishingStandard,
  Format,
  FormPropertyType,
  Foundation,
  Greide,
  Liquidity,
  Location,
  MarketPerformance,
  Offers,
  Paving,
  ProfileUsers,
  PropertyType,
  PropertyUse,
  PurposeEvaluation,
  QuestionType,
  RegistrationUf,
  ReportCondominiumPropertUse,
  ReportCondominiumType,
  ReportConservation,
  ReportFunction,
  ReportGoal,
  ReportPosition,
  ReportPropertUse,
  ReportStandard,
  Situation,
  Standard,
  StateOfConservation,
  TerrainPosition,
  Topography,
  Treatment,
  TypeOfOccupation,
  TypeOfProperty,
  VariableGrowth,
  VariableType,
  YesOrNo,
} from '../api/enumerations';

export interface SelectProps {
  value: number;
  label: string;
}

export const selectEvaluationType = (): SelectProps[] => {
  const enumValues = Object.values(EvaluationType).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: EvaluationType = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertEvaluationType(enumKey),
    };
  });
};

export const selectEvaluationTypeBank = (): SelectProps[] => {
  const enumValues = Object.values(EvaluationTypeBank).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: EvaluationTypeBank = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertEvaluationTypeBank(enumKey),
    };
  });
};

export const selectClientKind = (): SelectProps[] => {
  const enumValues = Object.values(ClientKind).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: ClientKind = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertClientKind(enumKey),
    };
  });
};

export const selectFormPropertyType = (): SelectProps[] => {
  const enumValues = Object.values(FormPropertyType).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: FormPropertyType = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertFormPropertyType(enumKey),
    };
  });
};

export const selectProfileUser = (): SelectProps[] => {
  const enumValues = Object.values(ProfileUsers).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: ProfileUsers = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertProfileUsers(enumKey),
    };
  });
};

export const selectPropertyType = (): SelectProps[] => {
  const enumValues = Object.values(PropertyType).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: PropertyType = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertPropertyType(enumKey),
    };
  });
};

export const selectReportCondominiumPropertyUse = (): SelectProps[] => {
  const enumValues = Object.values(ReportCondominiumPropertUse).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: ReportCondominiumPropertUse = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertReportCondominiumPropertyUse(enumKey),
    };
  });
};

export const selectReportPropertyUse = (): SelectProps[] => {
  const enumValues = Object.values(ReportPropertUse).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: ReportPropertUse = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertReportPropertyUse(enumKey),
    };
  });
};

export const selectPropertyUse = (): SelectProps[] => {
  const enumValues = Object.values(PropertyUse).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: PropertyUse = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertPropertyUse(enumKey),
    };
  });
};

export const selectQuestionType = (): SelectProps[] => {
  const enumValues = Object.values(QuestionType).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: QuestionType = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertQuestionType(enumKey),
    };
  });
};

export const selectRegistrationUf = (): SelectProps[] => {
  const enumValues = Object.values(RegistrationUf).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: RegistrationUf = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertRegistrationUf(enumKey),
    };
  });
};

export const selectReportFunction = (): SelectProps[] => {
  const enumValues = Object.values(ReportFunction).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: ReportFunction = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertReportFunction(enumKey),
    };
  });
};

export const selectReportGoal = (): SelectProps[] => {
  const enumValues = Object.values(ReportGoal).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: ReportGoal = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertReportGoal(enumKey),
    };
  });
};

export const selectConcept = (): SelectProps[] => {
  const enumValues = Object.values(Concept).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: Concept = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertConcept(enumKey),
    };
  });
};

export const selectConservation = (): SelectProps[] => {
  const enumValues = Object.values(ConservationState).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: ConservationState = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertConservationState(enumKey),
    };
  });
};

export const selectReportStandard = (): SelectProps[] => {
  const enumValues = Object.values(ReportStandard).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: ReportStandard = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertReportStandard(enumKey),
    };
  });
};

export const selectStandard = (): SelectProps[] => {
  const enumValues = Object.values(Standard).filter(
    (v) => !Number.isNaN(Number(v))
  );

  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: Standard = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertStandard(enumKey),
    };
  });
};

export const selectStandard400K = (): SelectProps[] => {
  const enumValues = Object.values(Standard).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: Standard = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertStandard400K(enumKey),
    };
  });
};

export const selectTreatment = (): SelectProps[] => {
  const enumValues = Object.values(Treatment).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: Treatment = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertTreatment(enumKey),
    };
  });
};

export const selectAccess = (): SelectProps[] => {
  const enumValues = Object.values(Access).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: Access = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertAccess(enumKey),
    };
  });
};

export const selectPaving = (): SelectProps[] => {
  const enumValues = Object.values(Paving).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: Paving = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertPaving(enumKey),
    };
  });
};

export const selectYesOrNo = (): SelectProps[] => {
  const enumValues = Object.values(YesOrNo).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: YesOrNo = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertYesOrNo(enumKey),
    };
  });
};

export const selectFormat = (): SelectProps[] => {
  const enumValues = Object.values(Format).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: Format = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertFormat(enumKey),
    };
  });
};

export const selectDrainage = (): SelectProps[] => {
  const enumValues = Object.values(Drainage).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: Drainage = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertDrainage(enumKey),
    };
  });
};

export const selectGreide = (): SelectProps[] => {
  const enumValues = Object.values(Greide).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: Greide = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertGreide(enumKey),
    };
  });
};

export const selectFence = (): SelectProps[] => {
  const enumValues = Object.values(Fence).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: Fence = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertFence(enumKey),
    };
  });
};

export const selectAbsorption = (): SelectProps[] => {
  const enumValues = Object.values(Absorption).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: Absorption = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertAbsorptionByTheMarket(enumKey),
    };
  });
};

export const selectMarketPerformance = (): SelectProps[] => {
  const enumValues = Object.values(MarketPerformance).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: MarketPerformance = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertMarketPerformance(enumKey),
    };
  });
};

export const selectOffers = (): SelectProps[] => {
  const enumValues = Object.values(Offers).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: Offers = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertOffers(enumKey),
    };
  });
};

export const selectLiquidity = (): SelectProps[] => {
  const enumValues = Object.values(Liquidity).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: Liquidity = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertLiquidity(enumKey),
    };
  });
};

export const selectReportConservation = (): SelectProps[] => {
  const enumValues = Object.values(ReportConservation).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: ReportConservation = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertReportConservation(enumKey),
    };
  });
};

export const selectReportCondominiumType = (): SelectProps[] => {
  const enumValues = Object.values(ReportCondominiumType).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: ReportCondominiumType = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertReportCondominiumType(enumKey),
    };
  });
};

export const selectReportPosition = (): SelectProps[] => {
  const enumValues = Object.values(ReportPosition).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: ReportPosition = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertReportPosition(enumKey),
    };
  });
};

export const selectStateOfConservation = (): SelectProps[] => {
  const enumValues = Object.values(StateOfConservation).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: StateOfConservation = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertStateOfConservation(enumKey),
    };
  });
};

export const selectFinishingStandard = (): SelectProps[] => {
  const enumValues = Object.values(FinishingStandard).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: FinishingStandard = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertFinishingStandard(enumKey),
    };
  });
};

export const selectLocation = (): SelectProps[] => {
  const enumValues = Object.values(Location).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: Location = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertLocation(enumKey),
    };
  });
};

export const selectDeployment = (): SelectProps[] => {
  const enumValues = Object.values(Deployment).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: Deployment = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertDeployment(enumKey),
    };
  });
};

export const selectTopography = (): SelectProps[] => {
  const enumValues = Object.values(Topography).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: Topography = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertTopography(enumKey),
    };
  });
};

export const selectSituation = (): SelectProps[] => {
  const enumValues = Object.values(Situation).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: Situation = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertSituation(enumKey),
    };
  });
};

export const selectFoundation = (): SelectProps[] => {
  const enumValues = Object.values(Foundation).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: Foundation = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertFoundation(enumKey),
    };
  });
};

export const selectBDI = (): SelectProps[] => {
  const enumValues = Object.values(BDI).filter((v) => !Number.isNaN(Number(v)));
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: BDI = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertBDI(enumKey),
    };
  });
};

export const selectVariableType = (): SelectProps[] => {
  const enumValues = Object.values(VariableType).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: VariableType = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertVariableType(enumKey),
    };
  });
};

export const selectVariableGrowth = (): { value: string; label: string }[] => {
  const enumValues = Object.values(VariableGrowth).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsString = VariableGrowth.POSITIVE ? 'positivo' : 'negativo';
    // const enumKey: VariableGrowth = valueAsNumber;
    return {
      value: valueAsString,
      label: convertVariableGrowth(value as VariableGrowth),
    };
  });
};

export const selectEvaluationMethodology = (): SelectProps[] => {
  const enumValues = Object.values(EvaluationMethodology).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: EvaluationMethodology = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertEvaluationMethodology(enumKey),
    };
  });
};

export const selectPurposeEvaluation = (): SelectProps[] => {
  const enumValues = Object.values(PurposeEvaluation).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: PurposeEvaluation = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertPurposeEvaluation(enumKey),
    };
  });
};

export const selectTypeOfOccupation = (): SelectProps[] => {
  const enumValues = Object.values(TypeOfOccupation).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: TypeOfOccupation = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertTypeOfOccupation(enumKey),
    };
  });
};

export const selectTypeOfProperty = (): SelectProps[] => {
  const enumValues = Object.values(TypeOfProperty).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: TypeOfProperty = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertTypeOfProperty(enumKey),
    };
  });
};

export const selectTerrainPosition = (): SelectProps[] => {
  const enumValues = Object.values(TerrainPosition).filter(
    (v) => !Number.isNaN(Number(v))
  );
  return enumValues.map((value) => {
    const valueAsNumber = Number(value);
    const enumKey: TerrainPosition = valueAsNumber;
    return {
      value: valueAsNumber,
      label: convertTerrainPosition(enumKey),
    };
  });
};
