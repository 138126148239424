import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { workOrderAPI } from '../../../api';
import { Status, StatusCode } from '../../../api/enumerations';
import { checkHiveRequest } from '../../../api/hive/workOrders';
import { WorkOrderData } from '../../../api/workOrders/types';
import { useAttachment } from '../../../hooks/useAttachmentFiles';
import { useChangeStatus } from '../../../hooks/useChangeStatus';
import useErrorMessage from '../../../hooks/useErrorMessage';
import useGeneral from '../../../hooks/useGeneral';
import useSearchCep from '../../../hooks/useSearchCep';
import useSnackbar from '../../../hooks/useSnackbar';
import useUploadFile from '../../../hooks/useUploadFile';

type LatLngLiteral = google.maps.LatLngLiteral;

interface ICompleteSections {
  reportData: boolean;
  propertyData: boolean;
  propertyAddress: boolean;
  propertyDetails: boolean;
}

interface EntranceHook {
  loadingPage: boolean;
  handleSubmit: (e: React.FormEvent) => Promise<void>;
  verifyCep: (cep: string) => Promise<void>;
  uploadFile: (
    e: ChangeEvent<HTMLInputElement>,
    value: string
  ) => Promise<void>;
  address: string;
  setAddress: (value: string) => void;
  addressNumber: string;
  setAddressNumber: (value: string) => void;
  age: number;
  setAge: (value: number) => void;
  buildingStandard: number;
  setBuildingStandard: (value: number) => void;
  builtArea: number;
  setBuiltArea: (value: number) => void;
  cep: string;
  setCep: (value: string) => void;
  cepError: boolean;
  city: string;
  setCity: (value: string) => void;
  client: string;
  setClient: (value: string) => void;
  clientType: number;
  setClientType: (value: number) => void;
  complement: string;
  setComplement: (value: string) => void;
  concept: number;
  setConcept: (value: number) => void;
  conservation: number;
  setConservation: (value: number) => void;
  district: string;
  setDistrict: (value: string) => void;
  evaluationType: number;
  setEvaluationType: (value: number) => void;
  goal: number;
  setGoal: (value: number) => void;
  judicialDistrict: string;
  setJudicialDistrict: (value: string) => void;
  parkingLots: number;
  setParkingLots: (value: number) => void;
  propertyFunction: number;
  setPropertyFunction: (value: number) => void;
  propertyType: number;
  setPropertyType: (value: number) => void;
  propertyUse: number;
  setPropertyUse: (value: number) => void;
  registerNumber: string;
  setRegisterNumber: (value: string) => void;
  registrationUf: number;
  setRegistrationUf: (value: number) => void;
  setRequiresInspection: (value: boolean) => void;
  rooms: number;
  setRooms: (value: number) => void;
  suites: number;
  setSuites: (value: number) => void;
  toilets: number;
  setToilets: (value: number) => void;
  totalArea: number;
  setTotalArea: (value: number) => void;
  uf: string;
  setUf: (value: string) => void;
  zone: number;
  setZone: (value: number) => void;
  registerFileName: string;
  iptuFileName: string;
  createdAt: string;
  downloadIptu: string;
  downloadRegister: string;
  referenceNumber: number;
  newProperty: boolean;
  searchMap: boolean;
  setSearchMap: (value: boolean) => void;
  setIsAddressEdited: (value: boolean) => void;
  pinPlace: LatLngLiteral;
  setPinPlace: (value: LatLngLiteral) => void;
  solicitor: string;
  setSolicitor: (value: string) => void;
  propertyData: WorkOrderData | undefined;
  submitLoading: boolean;
  completedSections: ICompleteSections;
  handleButtonClick: (buttonName: string) => void;
  loadingApprove: boolean;
  setLoadingApprove: (value: boolean) => void;
}

const useEntrance = (): EntranceHook => {
  const [address, setAddress] = useState('');
  const [addressNumber, setAddressNumber] = useState('');
  const [age, setAge] = useState(0);
  const [buildingStandard, setBuildingStandard] = useState(0);
  const [builtArea, setBuiltArea] = useState(0);
  const [cep, setCep] = useState('');
  const [cepError, setCepError] = useState(false);
  const [city, setCity] = useState('');
  const [client, setClient] = useState('');
  const [clientType, setClientType] = useState(0);
  const [complement, setComplement] = useState('');
  const [concept, setConcept] = useState(0);
  const [conservation, setConservation] = useState(0);
  const [district, setDistrict] = useState('');
  const [evaluationType, setEvaluationType] = useState(0);
  const [goal, setGoal] = useState(0);
  const [iptuFile, setIptuFile] = useState<File | string>();
  const [judicialDistrict, setJudicialDistrict] = useState('');
  const [parkingLots, setParkingLots] = useState(0);
  const [propertyFunction, setPropertyFunction] = useState(0);
  const [propertyType, setPropertyType] = useState(0);
  const [propertyUse, setPropertyUse] = useState(0);
  const [registerFile, setRegisterFile] = useState<File | string>();
  const [registerNumber, setRegisterNumber] = useState('');
  const [registrationUf, setRegistrationUf] = useState(0);
  const [requiresInspection, setRequiresInspection] = useState(true);
  const [rooms, setRooms] = useState(0);
  const [suites, setSuites] = useState(0);
  const [toilets, setToilets] = useState(0);
  const [totalArea, setTotalArea] = useState(0);
  const [uf, setUf] = useState('');
  const [zone, setZone] = useState(0);
  const [solicitor, setSolicitor] = useState('');

  const [propertyData, setPropertyData] = useState<WorkOrderData>();
  const [checkedAcceptance, setCheckedAcceptance] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [registerFileName, setRegisterFileName] = useState('');
  const [iptuFileName, setIptuFileName] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [downloadIptu, setDownloadIptu] = useState('');
  const [downloadRegister, setDownloadRegister] = useState('');
  const [referenceNumber, setReferenceNumber] = useState(0);
  const [newProperty, setNewProperty] = useState(true);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [searchMap, setSearchMap] = useState(true);
  const [isAddressEdited, setIsAddressEdited] = useState(false);
  const [pinPlace, setPinPlace] = useState<LatLngLiteral>({
    lat: -23.56162,
    lng: -46.65591,
  });
  const [completedSections, setCompletedSections] = useState({
    reportData: false,
    propertyData: false,
    propertyAddress: false,
    propertyDetails: false,
  });

  const { iptuLink, setIptuLink, registerLink, setRegisterLink } =
    useAttachment();
  const { navigateHome, checkPathname, osId } = useGeneral();
  const { handleStatus, loadingApprove, setLoadingApprove } = useChangeStatus();
  const { getErrorMessage } = useErrorMessage();
  const { handleSnackbar } = useSnackbar();
  const { handleFileUpload } = useUploadFile();
  const { handleSearchCep } = useSearchCep();
  const navigate = useNavigate();

  const isBank = process.env.REACT_APP_IS_BANK === 'true';
  const clickedButtonRef = useRef<string | null>(null);

  const handleButtonClick = (buttonName: string): void => {
    clickedButtonRef.current = buttonName;
  };

  const getRequestAcceptance = useCallback(async () => {
    try {
      const response = await checkHiveRequest(osId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data.acceptance_status === 'pending') {
        navigate(`/home/property/${osId}/accept`);
      } else {
        setLoadingPage(false);
        setCheckedAcceptance(true);
      }
    } catch (error) {
      handleSnackbar(getErrorMessage(error, true), true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataCallback = useCallback(async () => {
    if (checkPathname('/edit')) {
      setNewProperty(false);
      try {
        const response = await workOrderAPI.getWorkOrder(osId);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (!response.data || response.data.status !== Status.ENTRANCE) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        if (
          isBank &&
          response.data.worker_company_id !== null &&
          !checkedAcceptance
        ) {
          getRequestAcceptance();
        } else {
          setLoadingPage(false);
        }
        setPropertyData(response.data);
        setAddress(response.data.street);
        setAddressNumber(response.data.number);
        setAge(response.data.age);
        setBuildingStandard(response.data.constructive_standard);
        setBuiltArea(response.data.constructed_area);
        setCep(response.data.zip_code);
        setCity(response.data.city);
        setClient(response.data.client_name);
        setClientType(response.data.client_kind);
        setComplement(response.data.address_complement);
        setConcept(response.data.communities_kind);
        setConservation(response.data.preservation_state);
        setDistrict(response.data.district);
        setEvaluationType(response.data.evaluation_type);
        setGoal(response.data.report_goal);
        setDownloadIptu(response.data.iptu_document || '');
        setJudicialDistrict(response.data.judicial_district);
        setParkingLots(response.data.parking_spaces);
        setPropertyFunction(response.data.report_function);
        setPropertyType(response.data.real_estate_kind);
        setPropertyUse(response.data.use);
        setDownloadRegister(response.data.real_estate_registry || '');
        setRegisterNumber(response.data.registration_number);
        setRegistrationUf(response.data.uf);
        setRequiresInspection(response.data.requires_inspection);
        setRooms(response.data.bedrooms);
        setSuites(response.data.suites);
        setToilets(response.data.bathrooms);
        setTotalArea(response.data.total_area);
        setUf(response.data.state);
        setZone(response.data.zone);
        setReferenceNumber(response.data.reference_number);
        setCreatedAt(response.data.created_at);
        if (response.data.latitude && response.data.longitude) {
          setPinPlace({
            lat: Number(response.data.latitude),
            lng: Number(response.data.longitude),
          });
        }
        if (response.data.requester_name) {
          setSolicitor(response.data.requester_name);
        }
      } catch (error) {
        handleSnackbar(getErrorMessage(error, true), true);
      }
    } else {
      setNewProperty(true);
      setLoadingPage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iptuLink, registerLink]);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback, iptuLink, registerLink]);

  const uploadFile = async (
    e: ChangeEvent<HTMLInputElement>,
    fileType: string
  ): Promise<void> => {
    const files = handleFileUpload(e);
    if (files) {
      if (checkPathname('/edit')) {
        const formData = new FormData();
        if (fileType === 'register') {
          formData.append('real_estate_registry', files[0]);
        }
        if (fileType === 'iptu') {
          formData.append('iptu_document', files[0]);
        }

        try {
          const response = await workOrderAPI.editWorkOrder(osId, formData);

          if (response.detail.description) {
            throw new Error(response.detail.description);
          }

          if (response.detail.status_code !== StatusCode.OK) {
            throw new Error('Algo deu errado, tente novamente.');
          }

          handleSnackbar('Dados alterados com sucesso!', false);
          if (fileType === 'register') {
            setRegisterLink(files[0].name);
          }
          if (fileType === 'iptu') {
            setIptuLink(files[0].name);
          }
        } catch (error) {
          handleSnackbar(getErrorMessage(error), true);
        }
        return;
      }

      if (fileType === 'register') {
        setRegisterFileName(files[0].name);
        setRegisterFile(files[0]);
      }
      if (fileType === 'iptu') {
        setIptuFileName(files[0].name);
        setIptuFile(files[0]);
      }
    }
  };

  const verifyCep = async (cep: string): Promise<void> => {
    const addressData = await handleSearchCep(cep);
    if (addressData) {
      setCep(addressData.cep);
      setUf(addressData.uf);
      setCity(addressData.city);
      setDistrict(addressData.district);
      setAddress(addressData.address);
      setCepError(false);
    } else {
      setCepError(true);
    }
    setIsAddressEdited(true);
  };

  useEffect(() => {
    if (
      client !== '' &&
      clientType !== 0 &&
      goal !== 0 &&
      propertyFunction !== 0 &&
      propertyUse !== 0 &&
      evaluationType !== 0
    ) {
      setCompletedSections((prev) => ({ ...prev, reportData: true }));
    } else {
      setCompletedSections((prev) => ({ ...prev, reportData: false }));
    }
  }, [
    client,
    clientType,
    goal,
    solicitor,
    propertyFunction,
    propertyUse,
    evaluationType,
  ]);

  useEffect(() => {
    if (
      registerNumber !== '' &&
      judicialDistrict !== '' &&
      concept !== 0 &&
      propertyType !== 0 &&
      registrationUf !== 0
    ) {
      setCompletedSections((prev) => ({ ...prev, propertyData: true }));
    } else {
      setCompletedSections((prev) => ({ ...prev, propertyData: false }));
    }
  }, [registerNumber, propertyType, concept, judicialDistrict, registrationUf]);

  useEffect(() => {
    if (
      cep !== '' &&
      city !== '' &&
      district !== '' &&
      uf !== '' &&
      addressNumber !== '' &&
      address !== '' &&
      !isAddressEdited &&
      !cepError
    ) {
      setCompletedSections((prev) => ({ ...prev, propertyAddress: true }));
    } else {
      setCompletedSections((prev) => ({ ...prev, propertyAddress: false }));
    }
  }, [
    cep,
    city,
    district,
    uf,
    address,
    addressNumber,
    isAddressEdited,
    cepError,
  ]);

  useEffect(() => {
    if (buildingStandard !== 0 && conservation !== 0) {
      setCompletedSections((prev) => ({ ...prev, propertyDetails: true }));
    } else {
      setCompletedSections((prev) => ({ ...prev, propertyDetails: false }));
    }
  }, [buildingStandard, conservation]);

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    if (cepError) {
      handleSnackbar('Adicione um CEP válido.', true);
      return;
    }

    if (buildingStandard === 0 || conservation === 0) {
      handleSnackbar('Os dados de seleção são obrigatórios.', true);
      return;
    }

    if (
      clientType === 0 ||
      concept === 0 ||
      evaluationType === 0 ||
      goal === 0 ||
      propertyFunction === 0 ||
      propertyType === 0 ||
      propertyUse === 0 ||
      registrationUf === 0
    ) {
      handleSnackbar('Os dados de seleção são obrigatórios.', true);
      return;
    }

    if (isAddressEdited) {
      handleSnackbar('Pesquise a localização no mapa antes de salvar.', true);
      return;
    }

    const formData = new FormData();
    formData.append('street', address);
    formData.append('number', addressNumber);
    formData.append('age', age.toString());
    formData.append('constructive_standard', buildingStandard.toString());
    formData.append('constructed_area', builtArea.toString());
    formData.append('zip_code', cep);
    formData.append('city', city);
    formData.append('client_name', client);
    formData.append('client_kind', clientType.toString());
    formData.append('address_complement', complement);
    formData.append('communities_kind', concept.toString());
    formData.append('preservation_state', conservation.toString());
    formData.append('district', district);
    formData.append('evaluation_type', evaluationType.toString());
    formData.append('report_goal', goal.toString());
    formData.append('judicial_district', judicialDistrict);
    formData.append('parking_spaces', parkingLots.toString());
    formData.append('report_function', propertyFunction.toString());
    formData.append('real_estate_kind', propertyType.toString());
    formData.append('use', propertyUse.toString());
    formData.append('registration_number', registerNumber);
    formData.append('requester_name', solicitor);
    formData.append('uf', registrationUf.toString());
    formData.append('requires_inspection', requiresInspection.toString());
    formData.append('bedrooms', rooms.toString());
    formData.append('suites', suites.toString());
    formData.append('bathrooms', toilets.toString());
    formData.append('total_area', totalArea.toString());
    formData.append('state', uf);
    formData.append('zone', zone.toString());
    formData.append('latitude', pinPlace.lat.toString());
    formData.append('longitude', pinPlace.lng.toString());

    if (registerFile) {
      formData.append('real_estate_registry', registerFile);
    }

    if (iptuFile) {
      formData.append('iptu_document', iptuFile);
    }

    setSubmitLoading(true);

    if (checkPathname('/edit')) {
      try {
        const response = await workOrderAPI.editWorkOrder(osId, formData);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        if (clickedButtonRef.current === 'edit-approve') {
          handleStatus(osId, referenceNumber);
        } else {
          handleSnackbar('Dados alterados com sucesso!', false);
          setSubmitLoading(false);
          navigateHome();
        }
      } catch (error) {
        handleSnackbar(getErrorMessage(error), true);
        setSubmitLoading(false);
      }
    } else {
      try {
        const response = await workOrderAPI.createWorkOrder(formData);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        handleSnackbar(
          `Imóvel cadastrado com sucesso! Número de OS ${response.data?.reference_number}`,
          false
        );
        setSubmitLoading(false);
        navigateHome();
      } catch (error) {
        handleSnackbar(getErrorMessage(error), true);
        setSubmitLoading(false);
      }
    }
  };

  return {
    loadingPage,
    handleSubmit,
    verifyCep,
    uploadFile,
    address,
    setAddress,
    addressNumber,
    setAddressNumber,
    age,
    setAge,
    buildingStandard,
    setBuildingStandard,
    builtArea,
    setBuiltArea,
    cep,
    setCep,
    cepError,
    city,
    setCity,
    client,
    setClient,
    clientType,
    setClientType,
    complement,
    setComplement,
    concept,
    setConcept,
    conservation,
    setConservation,
    district,
    setDistrict,
    evaluationType,
    setEvaluationType,
    goal,
    setGoal,
    judicialDistrict,
    setJudicialDistrict,
    parkingLots,
    setParkingLots,
    propertyFunction,
    setPropertyFunction,
    propertyType,
    setPropertyType,
    propertyUse,
    setPropertyUse,
    registerNumber,
    setRegisterNumber,
    registrationUf,
    setRegistrationUf,
    setRequiresInspection,
    rooms,
    setRooms,
    suites,
    setSuites,
    toilets,
    setToilets,
    totalArea,
    setTotalArea,
    uf,
    setUf,
    zone,
    setZone,
    registerFileName,
    iptuFileName,
    createdAt,
    downloadIptu,
    downloadRegister,
    referenceNumber,
    newProperty,
    searchMap,
    setSearchMap,
    setIsAddressEdited,
    pinPlace,
    setPinPlace,
    solicitor,
    setSolicitor,
    propertyData,
    submitLoading,
    completedSections,
    handleButtonClick,
    loadingApprove,
    setLoadingApprove,
  };
};

export default useEntrance;
