/* eslint-disable react-hooks/exhaustive-deps */
import { Menu } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import {
  ClientKind,
  EvaluationType,
  Status,
  convertEvaluationType,
  convertStatus,
} from '../../../../api/enumerations';
import { AvmStatusData, WorkOrderData } from '../../../../api/workOrders/types';
import protoFaceWainting from '../../../../assets/avatars/Avatar-2.png';
import protoFaceFailed from '../../../../assets/avatars/Avatar-6.png';
import logoBb from '../../../../assets/images/bankLogo/logo-bb.svg';
import logoCaixa from '../../../../assets/images/bankLogo/logo-caixa.svg';
import { FlexSpaceBetweenBox } from '../../../../components/UI/Box';
import { InputTitle } from '../../../../components/UI/Typography';
import { Constants } from '../../../../constants/home';
import {
  IconCancelMS,
  IconMailMS,
  IconMoreHorizMS,
} from '../../../../constants/icons';
import { GlobalContext } from '../../../../context/global';
import { checkCardColor, formatDate } from '../../../../helpers';
import { useCancelWorkOrder } from '../../../../hooks/useCancelWorkOrder';
import {
  AvmStatusBox,
  BankFlag,
  CardContainer,
  CardLabel,
  CardTitle,
  info,
  NoticesBox,
  NoticesNumber,
  ProtoIcon,
  ScheduleBox,
  ScheduleTypography,
  StatusTypography,
  StyledCardMedia,
  StyledDivider,
  StyledIconButton,
  StyledMenuItem,
  TypeFlag,
} from './styles';

interface CardProps {
  cardData: WorkOrderData;
  setLastPage: (value: number) => void;
  setPage: (value: number) => void;
  update: boolean;
  setUpdate: (value: boolean) => void;
}

export function Card({
  cardData,
  setLastPage,
  setPage,
  update,
  setUpdate,
}: CardProps): JSX.Element {
  const [hasResponsible, setHasResponsible] = useState(false);
  const [limitAlert, setLimitAlert] = useState(false);
  const [limitOver, setLimitOver] = useState(false);
  const [calculationError, setCalculationError] = useState(false);
  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorMenu);
  const avmStatus = cardData.status === Status.CALCULATION;
  const hasSchedule =
    cardData.evaluation_type === EvaluationType.SIMPFACTORS ||
    cardData.evaluation_type === EvaluationType.SIMPINFERENCES;
  const responsibleStatus = [
    Status.INSPECTION,
    Status.SAMPLE_CREATION,
    Status.CALCULATION,
    Status.REPORT,
    Status.REVISION,
  ];

  const { handleCancelWorkOrder } = useCancelWorkOrder();
  const { company } = useContext(GlobalContext);

  const isBank = process.env.REACT_APP_IS_BANK === 'true';

  const openMenu = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setAnchorMenu(e.currentTarget);
  };
  const closeMenu = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setAnchorMenu(null);
  };

  useEffect(() => {
    if (responsibleStatus.includes(cardData.status) && hasSchedule) {
      setHasResponsible(true);
    }
    if (cardData.status === Status.CALCULATION) {
      if (cardData.avm_status) {
        if (
          cardData.avm_status.status === 'not found' ||
          cardData.avm_status.status === 'failed' ||
          cardData.avm_status.status === 'undetermined' ||
          (cardData.avm_status.failure_reason &&
            cardData.avm_status.failure_reason?.length > 0)
        ) {
          setCalculationError(true);
        }
      }
    }
    if (
      company?.show_work_orders_deadline_warning &&
      cardData.limit_finish_at
    ) {
      const dateOver = new Date(cardData.limit_finish_at);
      const d = new Date(cardData.limit_finish_at);
      const deadline = cardData.warning_deadline_in_days || 3;
      const dateAlert = new Date(d.setDate(d.getDate() - deadline));
      const today = new Date();
      today.setHours(23);

      if (today >= dateOver) {
        setLimitOver(true);
        return;
      }
      if (today >= dateAlert) {
        setLimitAlert(true);
      }
    }
  }, []);

  const handleAvmStatus = (avmStatus: AvmStatusData | null): string => {
    switch (avmStatus?.status) {
      case 'failed':
        return 'erro de cálculo';
      default:
        return 'calculando...';
    }
  };

  return (
    <CardContainer
      key={cardData.id}
      sx={{
        borderColor: checkCardColor(
          cardData.client_kind,
          limitOver,
          limitAlert,
          calculationError
        ),
        backgroundColor: limitAlert ? '#F8F7E4' : '#FAFCFF',
      }}
    >
      <FlexSpaceBetweenBox alignItems="center" mb="10px">
        <CardTitle>{cardData.reference_number}</CardTitle>
        <StyledIconButton color="primary" onClick={openMenu}>
          {IconMoreHorizMS}
        </StyledIconButton>
        <Menu anchorEl={anchorMenu} open={isMenuOpen} onClose={closeMenu}>
          <StyledMenuItem
            onClick={(e) => {
              e.preventDefault();
              handleCancelWorkOrder(cardData.id);
              setLastPage(0);
              setPage(1);
              setUpdate(!update);
            }}
          >
            {IconCancelMS}
            {Constants.cancelOs}
          </StyledMenuItem>
        </Menu>
      </FlexSpaceBetweenBox>
      <TypeFlag
        sx={{
          backgroundColor: checkCardColor(
            cardData.client_kind,
            limitOver,
            limitAlert
          ),
        }}
      />
      {cardData.client_kind === ClientKind.BRASIL_BANK && (
        <BankFlag image={logoBb} title="logo banco do brasil" />
      )}
      {cardData.client_kind === ClientKind.CAIXA && (
        <BankFlag image={logoCaixa} title="logo caixa" />
      )}
      <StyledCardMedia image={cardData.google_maps} title="mapa" />
      <FlexSpaceBetweenBox height="44px">
        <StatusTypography>{convertStatus(cardData.status)}</StatusTypography>
        {hasResponsible && (
          <ScheduleBox>
            <ScheduleTypography>{Constants.listSchedule}</ScheduleTypography>
            <InputTitle>
              {cardData.inspection?.schedule_at &&
                formatDate(cardData.inspection.schedule_at)}
            </InputTitle>
          </ScheduleBox>
        )}
      </FlexSpaceBetweenBox>
      <StyledDivider />
      <CardLabel late={limitOver}>
        {Constants.clientName}
        <span style={info}>{cardData.client_name}</span>
      </CardLabel>
      <CardLabel late={limitOver || calculationError}>
        {Constants.address}
        <span style={info}>
          {cardData.street}, {cardData.number}
        </span>
      </CardLabel>
      <CardLabel late={limitOver} sx={{ width: '88%' }}>
        {Constants.report}
        <span style={info}>
          {convertEvaluationType(cardData.evaluation_type)}
        </span>
      </CardLabel>
      {hasResponsible && (
        <CardLabel late={limitOver || calculationError}>
          {Constants.inspector}
          <span style={info}>
            {cardData.inspection && cardData.inspection.engineer_name}
          </span>
        </CardLabel>
      )}
      {avmStatus && (
        <AvmStatusBox>
          <ProtoIcon
            image={
              cardData.avm_status?.failure_reason
                ? protoFaceFailed
                : protoFaceWainting
            }
          />
          <p>{handleAvmStatus(cardData.avm_status)}</p>
        </AvmStatusBox>
      )}
      {isBank && cardData.notices && (
        <NoticesBox>
          {IconMailMS}
          <NoticesNumber>{cardData.notices}</NoticesNumber>
        </NoticesBox>
      )}
    </CardContainer>
  );
}
