/* eslint-disable react/function-component-definition */
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

type ReportCompletionState = {
  methodology: boolean;
  variables: boolean;
  evaluationSummary: boolean;
  evaluationResult: boolean;
  attachments: boolean;
  images: boolean;
  appraisalInformation: boolean;
  microregion: boolean;
  terrain: boolean;
  condominium: boolean;
  improvements: boolean;
  diagnostic: boolean;
  generalConditions: boolean;
  inspection: boolean;
  warrantyStatements: boolean;
  additionalInfo: boolean;
  finalRemarks: boolean;
};

type ReportCompletionContextType = {
  reportCompletion: ReportCompletionState;
  toggleCompletion: (
    section: keyof ReportCompletionState,
    value?: boolean
  ) => void; // Tipo atualizado aqui
};

// Valor inicial do contexto
const initialReportCompletion: ReportCompletionState = {
  methodology: false,
  variables: false,
  evaluationSummary: false,
  evaluationResult: false,
  attachments: false,
  images: false,
  appraisalInformation: false,
  microregion: false,
  terrain: false,
  condominium: false,
  improvements: false,
  diagnostic: false,
  generalConditions: false,
  inspection: false,
  warrantyStatements: false,
  additionalInfo: false,
  finalRemarks: false,
};

const ReportCompletionContext = createContext(
  {} as ReportCompletionContextType
);

export const ReportCompletionProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [reportCompletion, setReportCompletion] = useState(
    initialReportCompletion
  );

  const toggleCompletion = useCallback(
    (section: keyof ReportCompletionState, value?: boolean) => {
      setReportCompletion((prevState) => ({
        ...prevState,
        [section]: value !== undefined ? value : !prevState[section],
      }));
    },
    []
  );

  const contextValue = useMemo(
    () => ({
      reportCompletion,
      toggleCompletion,
    }),
    [reportCompletion, toggleCompletion]
  );

  return (
    <ReportCompletionContext.Provider value={contextValue}>
      {children}
    </ReportCompletionContext.Provider>
  );
};

export const useReportCompletion = (): ReportCompletionContextType => {
  const context = useContext(ReportCompletionContext);
  return context;
};
